import { TCharggingStation, TPlugs } from "Types";
type THeader = {
  displayClose: boolean;
  content: TCharggingStation;
  displayDirection: boolean;
  onGetDirection: (data: TCharggingStation) => void;
};
const Card: React.FC<THeader> = ({
  content,
  displayClose,
  onGetDirection,
  displayDirection,
}) => {
  return (
    <div className="rounded-md w-full">
      <div className="capitalize border-black flex justify-between pb-2 items-start gap-1">
        <div>
          <p className="font-normal text-lg">{content.name}</p>
          <p className="font-thin text-sm">{content.address}</p>
        </div>

        {/* {displayClose && <SlClose size={25} />} */}
      </div>
      <div className="">
        <div className="flex gap-2">
          <p className="text-md">
            Available Ports:&nbsp;
            <span className="uppercase">
              {content.plugs.map((value: TPlugs) => (
                <>
                  {value.type === "DC" ? (
                    <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                      {value.power} {value.type}
                    </span>
                  ) : (
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                      {value.power} {value.type}
                    </span>
                  )}
                </>
              ))}
            </span>
          </p>
        </div>
        <div className="flex">
          <p>Phone No: {content.telephone}</p>
        </div>
        {displayDirection && (
          <button
            className="bg-primary text-white p-2 rounded-md w-full mt-3"
            onClick={() => onGetDirection(content)}
          >
            Get Direction
          </button>
        )}
      </div>
    </div>
  );
};

export default Card;
