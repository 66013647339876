import React, { useEffect, useState } from "react";
import Card from "../../Components/Card/Card.Component";
import OpenStreetMap from "Components/Map/OpenStreetMap.Component";
import { TCharggingStation, TLocationData } from "Types";
import { EventEmitter } from "../../events";

const listOfStationsData: TCharggingStation[] = [
  {
    id: 1,
    uuid: "a2d65e64-e61b-49e5-a5fb-d315eb026f63",
    name: "Hotel Barahi Pokhara",
    city: "Pokhara",
    province: "3",
    address: "Lakeside Rd Street No 13, Pokhara 33700",
    telephone: "61460617",
    type: ["car"],
    latitude: "28.20844294018578",
    longitude: "83.95873289911418",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 1,
        station_id: 1,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 2,
    uuid: "887f2d17-0380-44d9-98ff-10c108352904",
    name: "Hyundai Service Kupondole",
    city: "Kathmandu",
    province: "3",
    address: "Kupondole Hts Rd, Lalitpur 44600",
    telephone: "01-5550380",
    type: ["car"],
    latitude: "27.69081751578234",
    longitude: "85.31137231461452",
    amenities: ["wifi", "parking", "restroom"],
    vendor: "Hyundai",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 2,
        station_id: 2,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 3,
    uuid: "41fc0185-2f3e-448d-8f29-7a0363a1f0aa",
    name: "Hyundai Sales/Service Tinkune",
    city: "Kathmandu",
    province: "3",
    address: "Hyundai Sales/Service Tinkune",
    telephone: "+97714111891",
    type: ["car"],
    latitude: "27.69166081858411",
    longitude: "85.31175442397266",
    amenities: [],
    vendor: "Hyundai",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 4,
    uuid: "e5538c0d-73a9-487c-b9cc-e91e597eb281",
    name: "Siddhartha Resort Chisapani - Hyundai",
    city: "Chisapani",
    province: "",
    address: "J7VJ+2H7, Chisapani 21800, Nepal",
    telephone: "+977 91-414000",
    type: ["car"],
    latitude: "28.64280045186809",
    longitude: "81.28244718064708",
    amenities: ["restroom", "parking", "accomodation", "food"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 3,
        station_id: 4,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 5,
    uuid: "03578ee2-5221-48a1-94a7-553309a86f6b",
    name: "Waterfront Resort, Hyundai AC Charger",
    city: "Pokhara",
    province: "Gandaki",
    address: "Lakeside Rd, Pokhara 33700",
    telephone: "+977 980-1166303",
    type: ["car"],
    latitude: "28.22194360420484",
    longitude: "83.95237718541088",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 4,
        station_id: 5,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 6,
    uuid: "db4856cb-19b2-425b-b37b-5f62e679c5f6",
    name: "Hotel Landmark, Pokhara",
    city: "Pokhara",
    province: "",
    address: "6X39+HRP, Pokhara 33700, Nepal",
    telephone: "+977061452908",
    type: ["car"],
    latitude: "28.208123909441294",
    longitude: "83.95759287600569",
    amenities: ["restroom", "parking", "accomodation", "food"],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 5,
        station_id: 6,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 7,
    uuid: "ccaeb466-735d-4fba-8ca0-16d70c6887c7",
    name: "Nepal Electricity Authority, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "6X39+HRP, Pokhara 33700",
    telephone: "-",
    type: ["car"],
    latitude: "28.204011869340352",
    longitude: "83.96968318944211",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-08T13:51:19.000000Z",
    image: null,
    plugs: [
      {
        id: 6,
        station_id: 7,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2024-03-08T13:01:35.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 8,
    uuid: "e36e62b6-b7e4-40d5-9f74-a2e12d2c1db7",
    name: "Pokhara Grande Hotel Hyundai AC charger",
    city: "Pokhara",
    province: "Gandaki",
    address: "5XRF+X8V, Pokhara 33700, Nepal",
    telephone: "+977 61-460210",
    type: ["car"],
    latitude: "28.192924428668842",
    longitude: "83.97451043637862",
    amenities: [
      "accomodation",
      "parking",
      "food",
      "restroom",
      "wifi",
      "coffee",
    ],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 7,
        station_id: 8,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 9,
    uuid: "b13177c9-f1d6-4d91-8308-d1d951bd0a36",
    name: "Hyundai Service Center, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "Pokhara-Baglung Hwy, Pokhara 33700, Nepal",
    telephone: "+977061533026",
    type: ["car"],
    latitude: "28.22235964127572",
    longitude: "83.98012117527051",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 8,
        station_id: 9,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 10,
    uuid: "ec4bda25-1ea7-4943-971f-ac332900b671",
    name: "Kia Showroom, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "Shiva chowk 10 Pokhara, Nepal 33800",
    telephone: "+9779856022609",
    type: ["car"],
    latitude: "28.20632121267923",
    longitude: "83.993315360127",
    amenities: ["parking", "restroom"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 9,
        station_id: 10,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 11,
    uuid: "1d78c452-8465-4dd4-a489-eab3740cbfc0",
    name: "Sansar trading Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "Chinedada Pokhara 14",
    telephone: "+9779806601817",
    type: ["car"],
    latitude: "28.17178721628872",
    longitude: "84.02293745734721",
    amenities: ["parking", "restroom", "wifi", "food"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 10,
        station_id: 11,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 13,
    uuid: "4834d19f-ffd7-46b6-8863-a5a4a9eb6354",
    name: "Baba Dham Traders Thee-Go",
    city: "Darechok",
    province: "",
    address: "Muktinath Pani Prashodhan, Prithvi Hwy, Darechok 44200, Nepal",
    telephone: "+97756410010",
    type: ["car"],
    latitude: "27.869839563840554",
    longitude: "84.60689773069713",
    amenities: ["restroom", "parking"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 12,
        station_id: 13,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 14,
    uuid: "28db0e96-a420-4553-928b-0b5911379e3e",
    name: "Riverside Spring Resort",
    city: "Darechok",
    province: "",
    address: "Riverside Spring Resort, Darechok, Nepal",
    telephone: "+977 980-1801336",
    type: ["car"],
    latitude: "27.87459589160929",
    longitude: "84.61683874738979",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 13,
        station_id: 14,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 39,
        station_id: 14,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-19T10:38:22.000000Z",
        updated_at: "2023-10-19T10:38:24.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 15,
    uuid: "23614eb8-4ab3-41a1-8e16-d34e7bb58434",
    name: "Siddhartha Riverside Resort",
    city: "Chumlingtar",
    province: "Bagmati",
    address: "H04, Darechok",
    telephone: "+9779851151478",
    type: ["car"],
    latitude: "27.859113648027954",
    longitude: "84.64669436485534",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-21T14:54:24.000000Z",
    image: null,
    plugs: [
      {
        id: 15,
        station_id: 15,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 17,
    uuid: "a3335103-9939-43b6-bb47-374ef34760b5",
    name: "Sajha Auto Center MG",
    city: "Bharatpur",
    province: "Bagmati",
    address: "Bypass Rd, Bharatpur 44207, Nepal",
    telephone: "+977056533190",
    type: ["car"],
    latitude: "27.677075223849222",
    longitude: "84.43936778704771",
    amenities: ["restroom"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 17,
        station_id: 17,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 18,
    uuid: "ca110576-a901-43ae-bd72-f3d5b0f70191",
    name: "Hyundai Sales/Service Bharatpur",
    city: "Bharatpur",
    province: "Bagmati",
    address: "Balmandir Rd 10, Bharatpur 44207, Nepal",
    telephone: "+9779801353765",
    type: ["car"],
    latitude: "27.665671248239722",
    longitude: "84.45012563995337",
    amenities: ["restroom", "parking"],
    vendor: "hyundai",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 18,
        station_id: 18,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 20,
    uuid: "018d7fee-fc1c-427f-b61c-9ca016456b67",
    name: "Hetauda Wheels, Hyundai",
    city: "Hetauda",
    province: "",
    address: "E - W Hwy, Hetauda 44107, Nepal",
    telephone: "+9779802641984",
    type: ["car"],
    latitude: "27.42563022702619",
    longitude: "85.03051390511445",
    amenities: ["restroom", "parking"],
    vendor: "hyundai",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 20,
        station_id: 20,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 21,
    uuid: "3d2354d9-f634-4e54-816e-94eb3b06abf3",
    name: "NEA Charging Station CCS, Hetauda Bus Park",
    city: "Hetauda",
    province: "Bagmati",
    address: "Ratomate, Hetauda, Makwanpur",
    telephone: "N/A",
    type: ["car"],
    latitude: "27.382002914522754",
    longitude: "85.02341531653538",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 21,
        station_id: 21,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 85,
        station_id: 21,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:08:37.000000Z",
        updated_at: "2023-10-25T18:08:39.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 23,
    uuid: "204d4cd8-d3fb-4895-8f28-29a8dacb493c",
    name: "Akshit Resort and Rafting",
    city: "Mulkot",
    province: "",
    address: "Mulkot Bazaar, Purano Jhangajholi 45400",
    telephone: "+977 9851045480",
    type: ["car"],
    latitude: "27.402299592428022",
    longitude: "85.9238229064206",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 23,
        station_id: 23,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 42,
        station_id: 23,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-20T17:56:57.000000Z",
        updated_at: "2023-10-20T17:57:00.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 25,
    uuid: "f8aa9839-3425-418f-b498-ed48f6700a05",
    name: "NEA Sindhuli Bus Park",
    city: "Sindhuli Bus Park",
    province: "Bagmati",
    address: "Sindhuli Bus Park, Kamalamai 45900, Nepal",
    telephone: null,
    type: ["car"],
    latitude: "27.21083697815945",
    longitude: "85.91105979444598",
    amenities: ["parking", "restroom"],
    vendor: "nea",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-02-27T07:12:42.000000Z",
    image: null,
    plugs: [
      {
        id: 25,
        station_id: 25,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2024-02-27T07:12:42.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
      {
        id: 56,
        station_id: 25,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-20T21:04:15.000000Z",
        updated_at: "2024-02-27T07:12:42.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 26,
    uuid: "aa4a4496-fddd-493c-bf3c-baab20fcc3fa",
    name: "Komal Hotel",
    city: "Mithila",
    province: "",
    address: "E W, पूर्व पश्चिम राजमार्ग, मिथिला 45600, Nepal",
    telephone: "+9779854022729",
    type: ["car"],
    latitude: "26.929392125865927",
    longitude: "85.95655890505292",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 26,
        station_id: 26,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 27,
        station_id: 26,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 28,
    uuid: "06ddca6c-90eb-408b-9fac-9bb123aa67f8",
    name: "Itahari -12 Khanar",
    city: "Itahari",
    province: "",
    address: "Koshi Rajmarg 12, Itahari 56705, Nepal",
    telephone: "+977 25-420111",
    type: ["car"],
    latitude: "26.627461064640677",
    longitude: "87.27373788802093",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 29,
        station_id: 28,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 29,
    uuid: "d98735e6-9f34-4fef-a7cd-6773bded0ff7",
    name: "Kesharichand Bhanwarlal Kia (Coming Soon)",
    city: "Biratnagar",
    province: "",
    address: "Near Radharaman Temple, Bargachhi, बिराटनगर 56613, Nepal",
    telephone: "+977 21-461127",
    type: ["car"],
    latitude: "26.474928860771737",
    longitude: "87.27610569327626",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 30,
        station_id: 29,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 30,
    uuid: "dc6d062b-e9ed-4d08-ae57-fa323d6e21df",
    name: "Makalu Television",
    city: "Biratnagar",
    province: "",
    address: "F73G+2HM, H08, Biratnagar 56613, Nepal",
    telephone: "",
    type: ["car"],
    latitude: "26.452499952525468",
    longitude: "87.27640270040658",
    amenities: ["restroom"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 31,
        station_id: 30,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 32,
        station_id: 30,
        plug: "wall-bs1363",
        power: "",
        type: "",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "wall-bs1363",
      },
    ],
  },
  {
    id: 31,
    uuid: "7f68c7fa-51c7-47f4-8329-d4ffc138c191",
    name: "Mahalaxmi International",
    city: "Birtamode",
    province: "",
    address: "E - W Hwy, Birtamode 57204, Nepal",
    telephone: "+977 23-545688",
    type: ["car"],
    latitude: "26.64090973303655",
    longitude: "87.95532421730184",
    amenities: ["parking"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 33,
        station_id: 31,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 32,
    uuid: "dfa880dd-61c0-4d8f-b87a-b373c299a8fa",
    name: "Hyundai Showroom, Thapathali",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Thapathali Road, Kathmandu 44600",
    telephone: "9801201011",
    type: ["car"],
    latitude: "27.692515343561293",
    longitude: "85.31886738744961",
    amenities: ["parking", "restroom"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-16T13:16:06.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 33,
    uuid: "56237695-7af2-4c05-84b5-e0a5963eb44a",
    name: "Labim Mall",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Pulchowk Gabahal Rd, Lalitpur",
    telephone: "9802358947",
    type: ["car"],
    latitude: "27.677488106320034",
    longitude: "85.31666395202585",
    amenities: ["parking", "food", "coffee", "restroom"],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-24T03:54:06.000000Z",
    image: null,
    plugs: [
      {
        id: 197,
        station_id: 33,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2024-03-08T13:59:32.000000Z",
        updated_at: "2024-03-08T13:59:32.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 34,
    uuid: "2263ddcb-7b14-46a2-9a37-b9df790d6e63",
    name: "Hyundai Sales Naxal",
    city: "Kathmandu",
    province: "3",
    address: "Naxal, Kathmandu",
    telephone: "+97714413934",
    type: ["car"],
    latitude: "27.71556151510917",
    longitude: "85.33003106227773",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 35,
    uuid: "92bdb2ce-f109-4d90-bc27-8bf5d9b71df0",
    name: "Trade Tower Thapathali",
    city: "Kathmandu",
    province: "3",
    address: "Thapathali, Kathmandu",
    telephone: "N/A",
    type: ["car"],
    latitude: "27.69459375947994",
    longitude: "85.31994729057585",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 36,
    uuid: "38f77254-74e5-4ee1-bad8-4915614acf1a",
    name: "Bauwa Hotel And Guest House",
    city: "Bardibas",
    province: "2",
    address: "XWJ9+2HQ, Mithila 45600",
    telephone: "+9779841808341",
    type: ["car"],
    latitude: "26.980336417677353",
    longitude: "85.91898930493367",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "byd",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-08T13:20:37.000000Z",
    image: null,
    plugs: [
      {
        id: 184,
        station_id: 36,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:19:08.000000Z",
        updated_at: "2024-03-08T13:19:08.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 37,
    uuid: "66120a53-41bc-4093-9c5b-89383d53c3e7",
    name: "BYD Park",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Thirbam Road, BYD Park, Kathmandu",
    telephone: "+97714441393",
    type: ["car"],
    latitude: "27.72036580899628",
    longitude: "85.33131856411678",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-08T13:39:03.000000Z",
    image: null,
    plugs: [
      {
        id: 191,
        station_id: 37,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:39:03.000000Z",
        updated_at: "2024-03-08T13:39:03.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 38,
    uuid: "bc7aa76f-ad1c-4016-8f21-5878e080a36c",
    name: "NEA Charging Station GB/T, Sajha Pulchowk",
    city: "Lalitpur",
    province: "Bagmati",
    address: "Sajha Petrol Pump, Pulchowk",
    telephone: "+97715552686",
    type: ["car"],
    latitude: "27.679438362398855",
    longitude: "85.31762265129962",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-01-06T08:12:27.000000Z",
    image: null,
    plugs: [
      {
        id: 164,
        station_id: 38,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T08:12:27.000000Z",
        updated_at: "2024-01-06T08:12:27.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 165,
        station_id: 38,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T08:12:27.000000Z",
        updated_at: "2024-01-06T08:12:27.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 40,
    uuid: "b32512d2-e481-44a9-b0e6-ffbb33f89a2d",
    name: "Hotel Gunja Bardibas",
    city: "Bardibas",
    province: "2",
    address: "Bardibas",
    telephone: "+97744550620",
    type: ["car"],
    latitude: "26.999713853956326",
    longitude: "85.90329853345283",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 49,
        station_id: 40,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:10:06.000000Z",
        updated_at: "2023-10-20T18:10:08.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 41,
    uuid: "9a634d72-b811-4be5-a750-2a8599d3ac49",
    name: "Jagat Motors",
    city: "Bhairawaha",
    province: "Lumbini",
    address: "Siddharthanagar, Bhairahawa",
    telephone: "+97771525428",
    type: ["car"],
    latitude: "27.50386430021768",
    longitude: "83.4561846502268",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 42,
    uuid: "9a281902-723c-4228-bb18-4046bd22e665",
    name: "Laxmi Dinesh 4 Wheelers",
    city: "Dhangadhi",
    province: "Sudurpaschim Province",
    address: "Main Road, Dhangadhi",
    telephone: "+97791524492",
    type: ["car"],
    latitude: "28.704641761732702",
    longitude: "80.56778570363397",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 43,
    uuid: "fd0627ad-6a24-4952-b16d-5f7ff8adc168",
    name: "Siddhartha Resort Chisapani",
    city: "Chisapani",
    province: "Karnali",
    address: "Chisapani Chisapani",
    telephone: "+97791414000",
    type: ["car"],
    latitude: "28.643017036827",
    longitude: "81.28250618044632",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 45,
    uuid: "0560a5a4-2881-4eb7-83f9-7895c6bde2bd",
    name: "Avocado & Orchid Resort",
    city: "Hetauda",
    province: "Bagmati",
    address:
      "Chaukitol -2, Hetauda Makawanpur Narayani Nepal, Tribhuvan Rajpath, Hetauda 44107",
    telephone: "+97757520235",
    type: ["car"],
    latitude: "27.435305854296722",
    longitude: "85.0358076522188",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 47,
    uuid: "48ec8cf8-4f9e-4783-ad16-c33d134b9a29",
    name: "Hotel The Kingsbury",
    city: "Birtamode",
    province: "1",
    address: "E - W Hwy, Birtamode 57204",
    telephone: "+97723509700",
    type: ["car"],
    latitude: "26.639386515973417",
    longitude: "87.97778701782491",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 48,
    uuid: "985fd4fa-589e-4b3e-886b-ef167f4bedf7",
    name: "Bodhi Redsun-Shinee Premiere",
    city: "Bhairahawa",
    province: "Lumbini",
    address: "Basantapur 8, Siddharthanagar 32900",
    telephone: "+97771525957",
    type: ["car"],
    latitude: "27.51315408771957",
    longitude: "83.476410364382978",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 49,
    uuid: "ffce7a7f-da13-4fa0-b16c-4465baa6f555",
    name: "Hotel Da Flamingo",
    city: "Butwal",
    province: "Lumbini",
    address: "Butwal 32907",
    telephone: "+97771420152",
    type: ["car"],
    latitude: "27.669669310224027",
    longitude: "83.46251608871846",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 50,
    uuid: "3c78f556-cf86-4060-9bcd-e60ff6255916",
    name: "Yatri Motorcycles Experience Center",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Thirbam Sadak, Kathmandu 44600",
    telephone: "+977980-1877447",
    type: ["bike"],
    latitude: "27.730905482675592",
    longitude: "85.330016348947",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 52,
    uuid: "981432da-1a54-4ea2-ba9f-bf48f111e14e",
    name: "Metro Hospitality Dang",
    city: "Dang",
    province: "Lumbini",
    address: "Tulsipur, Dang",
    telephone: "+9779868282249",
    type: ["car"],
    latitude: "28.13355155137408",
    longitude: "82.28707513285744",
    amenities: ["parking"],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-02-27T14:50:19.000000Z",
    image: null,
    plugs: [
      {
        id: 172,
        station_id: 52,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2024-02-27T14:50:19.000000Z",
        updated_at: "2024-02-27T14:50:19.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 53,
    uuid: "1ec7f963-8cc8-43a1-8274-899d9df0b218",
    name: "Hyundai Sales/Service Dang",
    city: "Dang",
    province: "Lumbini",
    address:
      "Hira Bhawan, Rabbe Tole, Ghorahi Lamahi - Tulsipur Road, 22415, Nepal",
    telephone: "+977082563634",
    type: ["car"],
    latitude: "28.029439122261298",
    longitude: "82.49279660346103",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 54,
    uuid: "4ebccc69-da27-4594-bc77-893778d54f17",
    name: "Ocean Auto AC Charger",
    city: "Butwal",
    province: "Lumbini",
    address: "MFPG+4WH, Butwal 32907, Nepal",
    telephone: "+9779857030252",
    type: ["car"],
    latitude: "27.68587931026063",
    longitude: "83.47720419304807",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 55,
    uuid: "140bfd6c-5f01-4d9b-b6f4-f8e391f95221",
    name: "Hyundai Sales/Service Butwal",
    city: "Butwal",
    province: "Lumbini",
    address: "kalikanager, Butwal 32907, Nepal",
    telephone: "+977071415278",
    type: ["car"],
    latitude: "27.673458261688964",
    longitude: "83.46439075215936",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 56,
    uuid: "879c3220-92bd-4e90-91c3-b3ebfb56e340",
    name: "Kuber Oil Services, KOS, Coffee & Restaurant",
    city: "Dumre",
    province: "Gandaki",
    address: "Thumka - Korikha - Bahunbhanjyan Rd, Bandipur 33914",
    telephone: "+9779856080622",
    type: ["car"],
    latitude: "27.966907044627355",
    longitude: "84.40475111763509",
    amenities: ["parking", "coffee", "restroom"],
    vendor: "byd",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-08T13:09:47.000000Z",
    image: null,
    plugs: [
      {
        id: 11,
        station_id: 56,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 57,
    uuid: "21eb26bb-4c4b-490d-9d41-9a5eb7224fed",
    name: "Gokarna Forest Resort",
    city: "Kathmandu",
    province: "Gandaki",
    address: "Nagpokhari Marg 390, Gokarneshwor 44600",
    telephone: "+977014451212",
    type: ["car"],
    latitude: "27.72962721027683",
    longitude: "85.39870581209675",
    amenities: [],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 58,
    uuid: "c355c5a7-634f-4fdb-9ee0-a7b407748c62",
    name: "Taj Riverside Resort",
    city: "Mulkot",
    province: "Bagmati",
    address: "Sunkoshi Rural Municipality Mulkot-5, B.P Highway",
    telephone: "+9779851088440",
    type: ["car"],
    latitude: "27.405068909370048",
    longitude: "85.92027607898733",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-17T07:21:13.000000Z",
    image: null,
    plugs: [
      {
        id: 24,
        station_id: 58,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2024-03-21T14:51:07.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 271,
        station_id: 58,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:21:13.000000Z",
        updated_at: "2024-03-17T07:21:13.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 60,
    uuid: "e4352633-b046-4623-98e3-3ebae2bbaef3",
    name: "Hyundai Charging Station",
    city: "Nepalgunj",
    province: "5",
    address: "Ratna Rajmarg, Near Nepalgunj Airport, Nepalgunj",
    telephone: "+977081550428",
    type: ["car"],
    latitude: "28.085241624010234",
    longitude: "81.6459016326406",
    amenities: ["parking"],
    vendor: "hyundai",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 61,
    uuid: "9e35e8cc-6800-4bab-a599-34a534b7847e",
    name: "Siddhartha Hotel",
    city: "Nepalgunj",
    province: "5",
    address: "Ratna Rajmarg, Nepalgunj",
    telephone: "081-551200",
    type: ["car"],
    latitude: "28.085374502267516",
    longitude: "81.64592335595455",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "hyundai",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 78,
        station_id: 61,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-25T11:48:58.000000Z",
        updated_at: "2023-10-25T11:49:00.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 62,
    uuid: "40f073b8-0bcb-4c2f-bd72-0670ddc8054d",
    name: "Sipradi Trading Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "Prithvi Hwy, Pokhara 33700",
    telephone: "+977061538499",
    type: ["car"],
    latitude: "28.20635208754521",
    longitude: "83.99581902578717",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-21T14:50:36.000000Z",
    image: null,
    plugs: [
      {
        id: 73,
        station_id: 62,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:45:34.000000Z",
        updated_at: "2023-10-24T22:45:36.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 63,
    uuid: "58c17ca6-6cd0-4b91-8831-3e59e06c5d70",
    name: "Quick20 Riverside Hotel",
    city: "Amlekhganj",
    province: "2",
    address: "E - W Hwy, Amlekhganj 44400",
    telephone: "+977053570030",
    type: ["car"],
    latitude: "27.296456544116467",
    longitude: "84.98966547326238",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 65,
    uuid: "ca02ddb7-1102-442d-b4e3-ff001fa0e754",
    name: "APF Kalika Fuel Station CCS",
    city: "Pokhara",
    province: "Gandaki",
    address: "Pokhara-Baglung Hwy, Pokhara 33700",
    telephone: "+977061583652",
    type: ["car"],
    latitude: "28.21876078252428",
    longitude: "83.97777172912065",
    amenities: ["restroom"],
    vendor: "nea",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 79,
        station_id: 65,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T17:31:44.000000Z",
        updated_at: "2023-10-25T17:31:46.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 80,
        station_id: 65,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T17:31:57.000000Z",
        updated_at: "2023-10-25T17:32:00.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 66,
    uuid: "845523dd-cac4-478d-b271-d83bc347f16b",
    name: "Club Denovo",
    city: "Butwal",
    province: "Lumbini",
    address: "Kalika Nagar 11, Butwal 32907",
    telephone: "+977071438885",
    type: ["car"],
    latitude: "27.683822466853208",
    longitude: "83.46122966332723",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 68,
    uuid: "566ef545-b3cc-4706-92de-a9d4ed8babf8",
    name: "Superior Service Center & Charging Station",
    city: "Biratnagar",
    province: "Province 1",
    address: "H08 Airport Chowk, Biratnagar 56613",
    telephone: "021463285",
    type: ["car"],
    latitude: "26.479376852855083",
    longitude: "87.2764390888327",
    amenities: ["wifi", "parking", "restroom"],
    vendor: "byd",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2024-03-08T13:18:23.000000Z",
    image: null,
    plugs: [
      {
        id: 34,
        station_id: 68,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2023-10-12T17:47:52.000000Z",
        updated_at: "2023-10-12T17:47:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 195,
        station_id: 68,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:48:58.000000Z",
        updated_at: "2024-03-08T13:48:58.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 69,
    uuid: "eca3f0c0-1c03-40bf-8e3c-40c3692cf85f",
    name: "Oliz Store Birtamode",
    city: "Birtamode",
    province: "Province 1",
    address: "Sanischare Rd, Birtamode 57204",
    telephone: "+9779741662239",
    type: ["car"],
    latitude: "26.655866644469278",
    longitude: "87.99383449244858",
    amenities: ["wifi", "parking", "restroom"],
    vendor: "mg",
    created_at: "2023-10-16T12:29:16.000000Z",
    updated_at: "2024-03-08T10:46:51.000000Z",
    image: null,
    plugs: [
      {
        id: 36,
        station_id: 69,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-16T12:50:02.000000Z",
        updated_at: "2023-10-16T12:50:04.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 45,
        station_id: 69,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:02:11.000000Z",
        updated_at: "2023-10-20T18:02:12.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 70,
    uuid: "bb2fdb48-46f4-4c0c-ac4a-dc63823da21b",
    name: "MG Dealership Birtamode",
    city: "Birtamode",
    province: "Province 1",
    address: "E W, E - W Hwy, Birtamode 57204",
    telephone: "+977023545688",
    type: ["car"],
    latitude: "26.640919309185243",
    longitude: "87.95537786265515",
    amenities: ["parking", "restroom"],
    vendor: "mg",
    created_at: "2023-10-16T12:33:01.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 47,
        station_id: 70,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:06:52.000000Z",
        updated_at: "2023-10-20T18:06:53.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 71,
    uuid: "f1e05a0c-e5ab-430d-a253-6e8898726d45",
    name: "Landmark Forest-Park",
    city: "Chitwan",
    province: "Bagmati",
    address:
      "Paryatan Margh, Chitwan National Park, Nepal, Baghmara Road 44200, Ratnanagar 00977",
    telephone: "+977056580311",
    type: ["car"],
    latitude: "27.587973125063375",
    longitude: "84.484959093689",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-16T12:35:43.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 53,
        station_id: 71,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:18:48.000000Z",
        updated_at: "2023-10-20T18:18:50.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 72,
    uuid: "c0887481-2212-4988-b161-977849a31440",
    name: "Hotel Royal Century",
    city: "Chitwan",
    province: "Bagmati",
    address: "Bharatpur Height, Bharatpur Muncipality, Chitwan",
    telephone: "+977056515865",
    type: ["car"],
    latitude: "27.68870945054449",
    longitude: "84.42802312384428",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-16T12:37:30.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 51,
        station_id: 72,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-20T18:14:28.000000Z",
        updated_at: "2023-10-20T18:14:29.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 52,
        station_id: 72,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:14:38.000000Z",
        updated_at: "2023-10-20T18:14:41.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 73,
    uuid: "89348096-41c4-4301-bb4c-2400b0be523b",
    name: "Ganapati Hotel & Resort",
    city: "Sindhuli",
    province: "Bagmati",
    address: "6WJ7+M6P, B.P. Highway, Kamalamai 45900",
    telephone: "+977047520346",
    type: ["car"],
    latitude: "27.2320156218908",
    longitude: "85.91316100908494",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-16T12:41:45.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 50,
        station_id: 73,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:11:09.000000Z",
        updated_at: "2023-10-20T18:11:11.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 74,
    uuid: "283c86ed-9d94-41a0-bdf4-c4ec26054d59",
    name: "Temple Tree Resort & Spa",
    city: "Pokhara",
    province: "Gandaki",
    address: "6th Street, Gaurighat, Lakeside Rd, Pokhara 33700",
    telephone: "+977061455819",
    type: ["car"],
    latitude: "28.206319823220067",
    longitude: "83.96303491866705",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-16T12:44:04.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 54,
        station_id: 74,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:22:59.000000Z",
        updated_at: "2023-10-20T18:23:01.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 76,
    uuid: "903660ac-97eb-441b-ba0d-e479e145487c",
    name: "Oliz Store Itahari | Apple Store | iPhone Store",
    city: "Itahari",
    province: "Province 2",
    address: "M79G+52F, H08 4, Itahari 56705",
    telephone: "+9779741662240",
    type: ["car"],
    latitude: "26.668327923964057",
    longitude: "87.27502508324703",
    amenities: ["wifi", "parking", "restroom"],
    vendor: "mg",
    created_at: "2023-10-16T12:49:03.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 35,
        station_id: 76,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-16T12:50:02.000000Z",
        updated_at: "2023-10-16T12:50:04.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 44,
        station_id: 76,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:01:11.000000Z",
        updated_at: "2023-10-20T18:01:13.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 77,
    uuid: "55517639-b22f-471d-9f82-4b267366a949",
    name: "Hotel Rajdurbar and Nabarang Resort Restaurant",
    city: "Janakpur",
    province: "Madhesh",
    address: "Mujeliya 14, Janakpur 45600",
    telephone: "+9779801668472",
    type: ["car"],
    latitude: "26.76831753009264",
    longitude: "85.93052337849176",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-16T12:55:40.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 48,
        station_id: 77,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:08:27.000000Z",
        updated_at: "2023-10-20T18:08:29.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 78,
    uuid: "a27abab4-e5ff-47cd-bd11-64faa3a25378",
    name: "Kl Traders, Hetauda, TCN Road",
    city: "Hetauda",
    province: "Bagmati",
    address: "C2GH+QHP, Bypass Marg - 1, Hetauda 44107",
    telephone: "+9779855064949",
    type: ["car"],
    latitude: "27.427202846642132",
    longitude: "85.02904496554524",
    amenities: ["parking", "restroom"],
    vendor: "mg",
    created_at: "2023-10-16T12:59:08.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 79,
    uuid: "e9f1afbd-1c8f-49f5-abab-7edd5a989ff0",
    name: "MG Dealership, Chitwan",
    city: "Chitwan",
    province: "Bagmati",
    address: "MC9Q+GHP, Bharatpur 44207",
    telephone: "+9779855033935",
    type: ["car"],
    latitude: "27.669812703436694",
    longitude: "84.43880096312073",
    amenities: ["parking"],
    vendor: "mg",
    created_at: "2023-10-16T15:14:59.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 38,
        station_id: 79,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-16T15:18:03.000000Z",
        updated_at: "2023-10-16T15:18:04.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 80,
    uuid: "b558bb10-42c0-4503-a2d0-02ce608092c2",
    name: "MG Dealership, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "6X6P+RWM, Nayabazar Rd, Pokhara 33700",
    telephone: "+9779851023714",
    type: ["car"],
    latitude: "28.212278458628706",
    longitude: "83.98737390198137",
    amenities: ["parking"],
    vendor: "mg",
    created_at: "2023-10-16T15:16:42.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 37,
        station_id: 80,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-16T15:17:24.000000Z",
        updated_at: "2023-10-16T15:17:26.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 81,
    uuid: "658c39b9-219e-4031-a2ea-221a15948490",
    name: "Hotel Pauwa Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "6X39+M7Q, Pokhara 33700",
    telephone: "+9779857072513",
    type: ["car"],
    latitude: "28.204413070402193",
    longitude: "83.96818670741688",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-19T10:43:10.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 40,
        station_id: 81,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-19T10:43:59.000000Z",
        updated_at: "2023-10-19T10:44:02.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 82,
    uuid: "7b914cd0-4fb8-4d80-9c1f-744e9ed4875a",
    name: "Hotel Jalsa",
    city: "Dhangadhi",
    province: "Sudurpashchim",
    address: "PH3F+VXG, Dhangadhi 10900",
    telephone: "+977091523977",
    type: ["car"],
    latitude: "28.70475580894577",
    longitude: "80.57494507315484",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-19T17:13:10.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 41,
        station_id: 82,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-19T17:13:41.000000Z",
        updated_at: "2023-10-19T17:13:43.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 83,
    uuid: "1c200d4d-79d6-4c59-85d4-b84a598b486b",
    name: "MG Service Center, Kusunti",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Kusunti, Kathmandu",
    telephone: "+977014514033",
    type: ["car"],
    latitude: "27.663348513008785",
    longitude: "85.31296814975482",
    amenities: ["parking", "restroom"],
    vendor: "mg",
    created_at: "2023-10-20T17:59:21.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 43,
        station_id: 83,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-20T17:59:58.000000Z",
        updated_at: "2023-10-20T18:00:00.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 84,
    uuid: "c6818d49-0679-4e76-867a-21bff0473968",
    name: "Hotel Pauwa, Bhairahawa",
    city: "Siddharthanagar",
    province: "Lumbini",
    address: "GC5V+X34, Siddharthanagar 32900",
    telephone: "+977071570305",
    type: ["car"],
    latitude: "27.510105298083257",
    longitude: "83.44275024903847",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-20T18:05:24.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 46,
        station_id: 84,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-20T18:05:49.000000Z",
        updated_at: "2023-10-20T18:05:50.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 85,
    uuid: "e68d2832-5307-4118-9fdb-e584ba9d29df",
    name: "Hotel Makalu Pvt. Ltd. Adarsh Nagar, Birgunj",
    city: "Birgunj",
    province: "Madhesh",
    address: "Makalu Hotel, Birgunj 44300",
    telephone: "+977051523054",
    type: ["car"],
    latitude: "27.00864853185427",
    longitude: "84.87422179202834",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "mg",
    created_at: "2023-10-20T18:28:34.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 55,
        station_id: 85,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-20T18:29:25.000000Z",
        updated_at: "2023-10-20T18:29:27.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 86,
    uuid: "7801a9fc-3ac0-4df4-81d5-c51f6546eb3a",
    name: "Auto Care Pvt Ltd",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Kathmandu 44600",
    telephone: "+977014114404",
    type: ["car"],
    latitude: "27.712325328755615",
    longitude: "85.35581493608728",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2023-10-20T18:46:21.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [],
  },
  {
    id: 87,
    uuid: "856bd9b6-fa39-4d1b-892b-bbe4a103e516",
    name: "Neha Oil Stores Pvt Ltd",
    city: "Sindhuli",
    province: "Bagmati",
    address: "B.P. Highway, Kamalamai 45900",
    telephone: "+9779844009006",
    type: ["car"],
    latitude: "27.12052538523896",
    longitude: "85.96009370472382",
    amenities: ["parking", "food", "coffee", "restroom"],
    vendor: "cg",
    created_at: "2023-10-21T16:13:48.000000Z",
    updated_at: "2024-03-17T16:39:51.000000Z",
    image: null,
    plugs: [
      {
        id: 57,
        station_id: 87,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-21T16:14:05.000000Z",
        updated_at: "2024-03-06T13:42:12.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 177,
        station_id: 87,
        plug: "gbt",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-06T13:42:12.000000Z",
        updated_at: "2024-03-06T13:42:12.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 88,
    uuid: "10b0b248-f859-45a2-b9e0-d971e8f9b724",
    name: "NEA Charging Station CCS, Itahari",
    city: "Itahari",
    province: "Koshi",
    address: "M73G+38Q, Itahari 56705",
    telephone: "-",
    type: ["car"],
    latitude: "26.65283366422833",
    longitude: "87.27617207133939",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-24T20:26:12.000000Z",
    updated_at: "2023-10-24T20:26:16.000000Z",
    image: null,
    plugs: [
      {
        id: 58,
        station_id: 88,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-24T20:27:45.000000Z",
        updated_at: "2023-10-24T20:27:47.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 59,
        station_id: 88,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-24T20:28:01.000000Z",
        updated_at: "2023-10-24T20:28:03.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 89,
    uuid: "e0ea7d45-58f3-4ad1-9cb0-a7a68e29ae2b",
    name: "NEA Charging Station GBT, Damak",
    city: "Damak",
    province: "Koshi",
    address: "Damak 5, Damak 57217",
    telephone: "-",
    type: ["car"],
    latitude: "26.65780796107563",
    longitude: "87.70191428183155",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-24T20:49:51.000000Z",
    updated_at: "2023-10-24T20:49:56.000000Z",
    image: null,
    plugs: [
      {
        id: 60,
        station_id: 89,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-24T20:51:23.000000Z",
        updated_at: "2023-10-24T20:51:24.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 61,
        station_id: 89,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-24T20:51:33.000000Z",
        updated_at: "2023-10-24T20:51:35.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 90,
    uuid: "9432bd92-beb3-48b3-8e06-11f807249b8a",
    name: "Pathivara Auto Link Hyundai",
    city: "Surunga",
    province: "Koshi",
    address: "Surunga, E - W Hwy, Kankai 57200, Nepal",
    telephone: "-",
    type: ["car"],
    latitude: "26.647063128769382",
    longitude: "87.88989527578272",
    amenities: ["parking", "restroom"],
    vendor: null,
    created_at: "2023-10-24T20:55:49.000000Z",
    updated_at: "2023-10-24T20:55:53.000000Z",
    image: null,
    plugs: [
      {
        id: 62,
        station_id: 90,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T20:56:13.000000Z",
        updated_at: "2023-10-24T20:56:14.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 91,
    uuid: "bbecb32d-27f4-4e2f-b0b5-1942c856281c",
    name: "Naivedya - A Family Restaurant",
    city: "Birtamode",
    province: "Koshi",
    address: "Ganapati Rd, Birtamode 57204",
    telephone: "+9779801455777",
    type: ["car"],
    latitude: "26.646997011774747",
    longitude: "88.00337289129894",
    amenities: ["parking", "restroom", "wifi", "food", "coffee"],
    vendor: null,
    created_at: "2023-10-24T21:01:36.000000Z",
    updated_at: "2023-10-24T21:01:40.000000Z",
    image: null,
    plugs: [
      {
        id: 63,
        station_id: 91,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-10-24T21:02:48.000000Z",
        updated_at: "2023-10-24T21:02:50.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 92,
    uuid: "8b644741-3ea8-4a18-8ae0-fb25adad58dc",
    name: "NEA Charging Station CCS, Lahan",
    city: "Madhupati",
    province: "Madhesh",
    address: "MGWF+4H, Madhupati 56400",
    telephone: "-",
    type: ["car"],
    latitude: "26.6952647026853",
    longitude: "86.52436330798899",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-24T21:27:02.000000Z",
    updated_at: "2024-01-06T07:48:29.000000Z",
    image: null,
    plugs: [
      {
        id: 64,
        station_id: 92,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-24T21:27:24.000000Z",
        updated_at: "2023-10-24T21:27:26.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 151,
        station_id: 92,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:48:29.000000Z",
        updated_at: "2024-01-06T07:48:29.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 93,
    uuid: "9c3de60a-dff6-4d82-bc20-41c26dac776c",
    name: "Sipradi Trading (TATA), Itahari",
    city: "Itahari",
    province: "Koshi",
    address: "Itahari 56705",
    telephone: null,
    type: ["car"],
    latitude: "26.672080333144528",
    longitude: "87.24234563785136",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:18:47.000000Z",
    updated_at: "2024-03-21T15:08:58.000000Z",
    image: null,
    plugs: [
      {
        id: 65,
        station_id: 93,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:19:11.000000Z",
        updated_at: "2024-03-21T15:08:58.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 111,
        station_id: 93,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 94,
    uuid: "cae1fc30-d330-47a4-b4df-61d5313a876b",
    name: "Sipradi Trading (TATA), Dhaijan",
    city: "Dhaijan",
    province: "Koshi",
    address: "Mahendra Hwy, Dhaijan",
    telephone: "+977023460037",
    type: ["car"],
    latitude: "26.653628388983392",
    longitude: "88.07426206172343",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:22:44.000000Z",
    updated_at: "2024-03-21T15:08:23.000000Z",
    image: null,
    plugs: [
      {
        id: 66,
        station_id: 94,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2024-03-21T15:08:23.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 108,
        station_id: 94,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:18.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 95,
    uuid: "28401923-fe9f-479e-9d16-d644be287dcc",
    name: "Hotel Batika",
    city: "Lahan",
    province: "Madhesh",
    address: "Dhangadhimai Nagarpalika-1, Lahan 56500",
    telephone: "9801089801",
    type: ["car"],
    latitude: "26.72889858027468",
    longitude: "86.43368899224325",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-24T22:26:04.000000Z",
    updated_at: "2024-03-22T16:24:16.000000Z",
    image: null,
    plugs: [
      {
        id: 67,
        station_id: 95,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:26:21.000000Z",
        updated_at: "2024-03-21T15:15:42.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 96,
    uuid: "9907b9dc-4c2e-420b-bd2a-aaec1b06183c",
    name: "Sipradi Trading (TATA), Simara",
    city: "Simara",
    province: "Madhesh",
    address: "5X3C+XH6, Gadhimai 44400",
    telephone: null,
    type: ["car"],
    latitude: "27.155194010366863",
    longitude: "84.97169358555466",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:28:15.000000Z",
    updated_at: "2024-03-21T15:04:48.000000Z",
    image: null,
    plugs: [
      {
        id: 68,
        station_id: 96,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:28:36.000000Z",
        updated_at: "2024-03-21T15:04:48.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 115,
        station_id: 96,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 97,
    uuid: "1109cf31-c99e-4d89-92bb-c0895f60ef44",
    name: "Sipradi Trading (TATA), Basamadi, Hetauda",
    city: "Hetauda",
    province: "Bagmati",
    address: "Hetauda 44100",
    telephone: "+9779801909862",
    type: ["car"],
    latitude: "27.45526796166114",
    longitude: "84.97048381051185",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:29:58.000000Z",
    updated_at: "2024-03-21T15:03:48.000000Z",
    image: null,
    plugs: [
      {
        id: 69,
        station_id: 97,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:30:16.000000Z",
        updated_at: "2024-03-21T15:02:58.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 98,
    uuid: "f3abc4c3-a885-45c1-9f77-5836d64d7de4",
    name: "Tata Motors Sipradi, Balkumari, Kathmandu",
    city: "Kathmandu",
    province: "Bagmati",
    address: "M8FR+4FH, Sankhamul - Balkumari Rd, Lalitpur 44600",
    telephone: "-",
    type: ["car"],
    latitude: "27.672859367780415",
    longitude: "85.34123965242946",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:33:17.000000Z",
    updated_at: "2024-03-21T14:51:37.000000Z",
    image: null,
    plugs: [
      {
        id: 70,
        station_id: 98,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:33:33.000000Z",
        updated_at: "2024-03-21T14:51:37.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 99,
    uuid: "0f7a6cde-0a73-49d9-8d5d-072808af3f52",
    name: "Tata Motors DC Charging Station",
    city: "Kathmandu",
    province: "Bagmati",
    address: "M8F2+GQM, Bagdol Rd, Lalitpur 44600",
    telephone: "-",
    type: ["car"],
    latitude: "27.673852599289997",
    longitude: "85.30192390408807",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:35:29.000000Z",
    updated_at: "2023-10-24T22:35:32.000000Z",
    image: null,
    plugs: [
      {
        id: 71,
        station_id: 99,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:35:47.000000Z",
        updated_at: "2023-10-24T22:35:48.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 100,
    uuid: "d2568448-df1a-4441-b62e-c96a5b271079",
    name: "Sipradi Trading (TATA), Chitwan",
    city: "Chitwan",
    province: "Bagmati",
    address: "MCFM+2FG, Mini Ring Rd, Bharatpur 44207",
    telephone: "-",
    type: ["car"],
    latitude: "27.67258221933858",
    longitude: "84.43373899086163",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:43:32.000000Z",
    updated_at: "2023-10-24T22:43:36.000000Z",
    image: null,
    plugs: [
      {
        id: 72,
        station_id: 100,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:43:49.000000Z",
        updated_at: "2023-10-24T22:43:51.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 101,
    uuid: "4eb36bdd-d4c3-4370-8fcc-dadac1ed0f58",
    name: "Sipradi Trading (TATA), Bhairawaha",
    city: "Bhairawaha",
    province: "Lumbini",
    address: "HFMC+FPR, Patthardada 32903",
    telephone: "+977071561033",
    type: ["car"],
    latitude: "27.586142745313985",
    longitude: "83.48008366272673",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:50:13.000000Z",
    updated_at: "2024-03-21T14:56:05.000000Z",
    image: null,
    plugs: [
      {
        id: 74,
        station_id: 101,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:50:37.000000Z",
        updated_at: "2024-03-21T14:56:05.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 102,
    uuid: "d97398b8-8aca-4ca5-9094-fcee2ad2d084",
    name: "Sipradi Trading (TATA), Nepalgunj",
    city: "Nepalgunj",
    province: "Karnali",
    address: "Janki, 21900",
    telephone: "-",
    type: ["car"],
    latitude: "28.130615504193496",
    longitude: "81.66468481550653",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:58:09.000000Z",
    updated_at: "2024-03-21T14:59:29.000000Z",
    image: null,
    plugs: [
      {
        id: 75,
        station_id: 102,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:58:27.000000Z",
        updated_at: "2024-03-21T14:59:11.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 103,
    uuid: "b8b6f839-8477-4f86-8fba-bef1b455ba89",
    name: "Sipradi Trading Pvt Ltd, Dhangadhi",
    city: "Dhangadhi",
    province: "Sudurpaschim",
    address: "PHQ9+W6P, Dhangadhi 10900",
    telephone: "091415051",
    type: ["car"],
    latitude: "28.73991849015513",
    longitude: "80.56724388205073",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T23:00:18.000000Z",
    updated_at: "2024-03-21T15:01:59.000000Z",
    image: null,
    plugs: [
      {
        id: 284,
        station_id: 103,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T15:01:59.000000Z",
        updated_at: "2024-03-21T15:01:59.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 104,
    uuid: "c485aba8-f9f2-40a3-8d7a-1e2b7d5bf2c6",
    name: "Siddhababa Charging Station",
    city: "Sindhuli",
    province: "Bagmati",
    address: "Kamalamai 45900",
    telephone: "+9779822075501",
    type: ["car"],
    latitude: "27.233611445234487",
    longitude: "85.91357590202892",
    amenities: ["parking", "restroom", "food", "coffee"],
    vendor: null,
    created_at: "2023-10-25T09:38:34.000000Z",
    updated_at: "2023-10-25T09:39:14.000000Z",
    image: null,
    plugs: [
      {
        id: 76,
        station_id: 104,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T09:43:11.000000Z",
        updated_at: "2023-10-25T09:43:12.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 77,
        station_id: 104,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T09:43:26.000000Z",
        updated_at: "2023-10-25T09:43:27.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 105,
    uuid: "53602e09-0b6d-4824-a4c0-490a6e0c15c5",
    name: "NEA Charging Station GB/T, Biratnagar",
    city: "Biratnagar",
    province: "Koshi",
    address: "Kavi Lekhnath Marg, Biratnagar 56613",
    telephone: null,
    type: ["car"],
    latitude: "26.444746916231352",
    longitude: "87.26990637861482",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T17:55:51.000000Z",
    updated_at: "2024-01-06T07:37:29.000000Z",
    image: null,
    plugs: [
      {
        id: 81,
        station_id: 105,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T17:56:35.000000Z",
        updated_at: "2024-01-06T07:37:29.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 82,
        station_id: 105,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T17:56:46.000000Z",
        updated_at: "2024-01-06T07:37:29.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 106,
    uuid: "46da3c20-8e37-4a2f-9df5-94c558888d02",
    name: "NEA Charging Station, Belaka",
    city: "Belaka",
    province: "Koshi",
    address: "Rampur Thokshila, Belaka Udayapur",
    telephone: "N/A",
    type: ["car"],
    latitude: "26.81716241174919",
    longitude: "87.0583841946059",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:02:05.000000Z",
    updated_at: "2023-10-25T18:02:08.000000Z",
    image: null,
    plugs: [
      {
        id: 83,
        station_id: 106,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:02:34.000000Z",
        updated_at: "2023-10-25T18:02:35.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 84,
        station_id: 106,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:02:51.000000Z",
        updated_at: "2023-10-25T18:02:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 107,
    uuid: "d80a3f9c-635c-4051-b615-093dc7fc6f96",
    name: "NEA Charging Station GB/T, Kohalpur",
    city: "Kohalpur",
    province: "Karnali",
    address: "Kohalpur Buspark",
    telephone: "N/A",
    type: ["car"],
    latitude: "28.196802429421204",
    longitude: "81.69345601052855",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:13:57.000000Z",
    updated_at: "2023-10-25T18:14:00.000000Z",
    image: null,
    plugs: [
      {
        id: 88,
        station_id: 107,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:16:42.000000Z",
        updated_at: "2023-10-25T18:16:44.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 89,
        station_id: 107,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:16:54.000000Z",
        updated_at: "2023-10-25T18:16:56.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 108,
    uuid: "53927821-994b-4033-b8cb-ff837d98510b",
    name: "NEA Charging Station CCS, Kohalpur",
    city: "Kohalpur",
    province: "Karnali",
    address: "Kohalpur Buspark",
    telephone: "N/A",
    type: ["car"],
    latitude: "28.196802429421204",
    longitude: "81.69345601052855",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:13:57.000000Z",
    updated_at: "2023-10-25T18:14:00.000000Z",
    image: null,
    plugs: [
      {
        id: 90,
        station_id: 108,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:17:16.000000Z",
        updated_at: "2023-10-25T18:17:18.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 91,
        station_id: 108,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:17:27.000000Z",
        updated_at: "2023-10-25T18:17:28.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 110,
    uuid: "3d2354d9-f634-4e54-816e-94eb3b06abf3",
    name: "NEA Charging Station GB/T, Hetauda Bus Park",
    city: "Hetauda",
    province: "Bagmati",
    address: "Ratomate, Hetauda, Makwanpur",
    telephone: "N/A",
    type: ["car"],
    latitude: "27.382002914522754",
    longitude: "85.02341531653538",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 86,
        station_id: 110,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:09:18.000000Z",
        updated_at: "2023-10-25T18:09:20.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 87,
        station_id: 110,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:09:30.000000Z",
        updated_at: "2023-10-25T18:09:31.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 111,
    uuid: "8cab4b16-681f-4ce3-ab73-d65247d2309d",
    name: "NEA Charging Station GB/T, Surkhet Bus Park",
    city: "Surkhet",
    province: "Karnali",
    address: "Surkhet Buspark, Birendranagar",
    telephone: "N/A",
    type: ["car"],
    latitude: "28.5909223380197",
    longitude: "81.61865222777662",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:33:37.000000Z",
    updated_at: "2023-10-25T18:33:40.000000Z",
    image: null,
    plugs: [
      {
        id: 92,
        station_id: 111,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:34:13.000000Z",
        updated_at: "2023-10-25T18:34:15.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 93,
        station_id: 111,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:34:27.000000Z",
        updated_at: "2023-10-25T18:34:29.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 112,
    uuid: "440170b2-7664-44f7-b9e4-d0b03ad1dccd",
    name: "NEA Charging Station CCS, Surkhet Bus Park",
    city: "Surkhet",
    province: "Karnali",
    address: "Surkhet Buspark, Birendranagar",
    telephone: "N/A",
    type: ["car"],
    latitude: "28.5909223380197",
    longitude: "81.61865222777662",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:33:37.000000Z",
    updated_at: "2023-10-25T18:33:40.000000Z",
    image: null,
    plugs: [
      {
        id: 94,
        station_id: 112,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:35:10.000000Z",
        updated_at: "2023-10-25T18:35:12.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 95,
        station_id: 112,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:35:21.000000Z",
        updated_at: "2023-10-25T18:35:22.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 113,
    uuid: "5ca5632e-cc69-4bed-b3a8-6fa1f4ddb3eb",
    name: "NEA Charging Station GB/T, Lamahi",
    city: "Lamahi",
    province: "Lumbini",
    address: "VGF8+GVQ, Lamahi 22414",
    telephone: "N/A",
    type: ["car"],
    latitude: "27.874070067473674",
    longitude: "82.5172447878673",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:39:27.000000Z",
    updated_at: "2023-10-25T18:39:30.000000Z",
    image: null,
    plugs: [
      {
        id: 96,
        station_id: 113,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:40:00.000000Z",
        updated_at: "2023-10-25T18:40:02.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 97,
        station_id: 113,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:40:12.000000Z",
        updated_at: "2023-10-25T18:40:13.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 114,
    uuid: "543e739e-2003-4808-b207-5b45b2773ad7",
    name: "NEA Charging Station CCS, Lamahi",
    city: "Lamahi",
    province: "Lumbini",
    address: "VGF8+GVQ, Lamahi 22414",
    telephone: "N/A",
    type: ["car"],
    latitude: "27.874070067473674",
    longitude: "82.5172447878673",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:39:27.000000Z",
    updated_at: "2023-10-25T18:39:30.000000Z",
    image: null,
    plugs: [
      {
        id: 98,
        station_id: 114,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:40:00.000000Z",
        updated_at: "2023-10-25T18:40:02.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 99,
        station_id: 114,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:40:00.000000Z",
        updated_at: "2023-10-25T18:40:02.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 115,
    uuid: "bb42c366-a587-42c3-80d9-5e99a7a596d8",
    name: "NEA Charging Station GB/T, Khurkot",
    city: "Khurkot",
    province: "Bagmati",
    address: "Bhimeshwor 45900",
    telephone: "N/A",
    type: ["car"],
    latitude: "27.3349454073039",
    longitude: "85.99164520756824",
    amenities: ["parking", "restroom", "food"],
    vendor: "nea",
    created_at: "2023-10-25T18:48:47.000000Z",
    updated_at: "2023-10-25T18:48:50.000000Z",
    image: null,
    plugs: [
      {
        id: 100,
        station_id: 115,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:49:02.000000Z",
        updated_at: "2023-10-25T18:49:04.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 101,
        station_id: 115,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:49:14.000000Z",
        updated_at: "2023-10-25T18:49:16.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 116,
    uuid: "4bf654fc-56b4-4562-b1e5-1ae343eda643",
    name: "NEA Charging Station GB/T, Birtamode",
    city: "Birtamode",
    province: "Koshi",
    address: "NewBuspark, Arjundhara11, Shani Arjun 57204",
    telephone: "N/A",
    type: ["car"],
    latitude: "26.652134708190864",
    longitude: "88.00557565676846",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:54:33.000000Z",
    updated_at: "2023-10-25T18:54:36.000000Z",
    image: null,
    plugs: [
      {
        id: 102,
        station_id: 116,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:55:01.000000Z",
        updated_at: "2023-10-25T18:55:03.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 103,
        station_id: 116,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:55:11.000000Z",
        updated_at: "2023-10-25T18:55:12.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 117,
    uuid: "4af507df-7c5d-471b-8e5e-b18ddea35e39",
    name: "NEA Charging Station CCS, Birtamode",
    city: "Birtamode",
    province: "Koshi",
    address: "NewBuspark, Arjundhara11, Shani Arjun 57204",
    telephone: "N/A",
    type: ["car"],
    latitude: "26.652237409491768",
    longitude: "88.00462447316424",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-25T18:54:33.000000Z",
    updated_at: "2023-10-25T18:54:36.000000Z",
    image: null,
    plugs: [
      {
        id: 104,
        station_id: 117,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-25T18:56:01.000000Z",
        updated_at: "2023-10-25T18:56:03.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 105,
        station_id: 117,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-25T18:56:01.000000Z",
        updated_at: "2023-10-25T18:56:03.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 118,
    uuid: "f98b47f5-d7ff-4eef-b005-07ad30cd8b3b",
    name: "NEA Charging Station CCS, Damak",
    city: "Damak",
    province: "Koshi",
    address: "Damak 5, Damak 57217",
    telephone: "-",
    type: ["car"],
    latitude: "26.65780796107563",
    longitude: "87.70191428183155",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-24T20:49:51.000000Z",
    updated_at: "2023-10-24T20:49:56.000000Z",
    image: null,
    plugs: [
      {
        id: 106,
        station_id: 118,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-24T20:51:23.000000Z",
        updated_at: "2023-10-24T20:51:24.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 107,
        station_id: 118,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-24T20:51:23.000000Z",
        updated_at: "2023-10-24T20:51:24.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 119,
    uuid: "ee0e0662-f8d8-4396-975f-e77fb60660a4",
    name: "Alaska Automobiles Pvt. Ltd.",
    city: "Birtamode",
    province: "Koshi",
    address: "JXWV+X4W, Sanischare Rd, Birtamode 57204",
    telephone: "+977023543929",
    type: ["car"],
    latitude: "26.647692765469586",
    longitude: "87.99278512241409",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 109,
        station_id: 119,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 120,
    uuid: "52157cbf-38f5-4903-aabf-0956be5bffde",
    name: "Goel Auto Pvt Ltd",
    city: "Biratnagar",
    province: "Koshi",
    address: "Airport Rd Chowk, Biratnagar 56613",
    telephone: "+9779801437291",
    type: ["car"],
    latitude: "26.48072881061318",
    longitude: "87.2774951312493",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 110,
        station_id: 120,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 121,
    uuid: "589367b5-87d8-4f9a-92ea-d64f73935723",
    name: "Girija Automobiles Enterprises",
    city: "Janakpur",
    province: "Madhesh",
    address: "PW5J+25F, गिरिजा पथ, Janakpur 45600",
    telephone: "+977041527998",
    type: ["car"],
    latitude: "26.70773224272666",
    longitude: "85.93085534920971",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2024-03-16T12:23:44.000000Z",
    image: null,
    plugs: [
      {
        id: 112,
        station_id: 121,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 122,
    uuid: "58c238e6-e688-4547-a625-089610384097",
    name: "Sipradi Trading (TATA), Dhalkebar",
    city: "Dhalkebar",
    province: "Madhesh",
    address: "WXP2+VQV, Mithila 45600",
    telephone: "+9779814837866",
    type: ["car"],
    latitude: "26.937406963015825",
    longitude: "85.95164635385623",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2024-03-21T15:18:16.000000Z",
    image: null,
    plugs: [
      {
        id: 113,
        station_id: 122,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 114,
        station_id: 122,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 123,
    uuid: "fddaa0d7-e15b-4511-9510-b13b9ac5df60",
    name: "Panorama Hotel And Lodge",
    city: "Fikkal",
    province: "Koshi",
    address: "Mechi Hwy 8, Suryodaya 57312",
    telephone: "+9779842725114",
    type: ["car"],
    latitude: "26.886064743140494",
    longitude: "88.07576333164567",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 116,
        station_id: 123,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 124,
    uuid: "8b25cf65-537e-4753-a321-6fa32190ffce",
    name: "Manaswi Hotel",
    city: "Phidim",
    province: "Koshi",
    address: "4QW8+4VV, LR6, Phidim 57400",
    telephone: "+977024523111",
    type: ["car"],
    latitude: "27.145436066476364",
    longitude: "87.76747308905135",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 117,
        station_id: 124,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 125,
    uuid: "7803fc62-be5a-4797-8563-358dad505b21",
    name: "Kewal Auto Service",
    city: "Dharan",
    province: "Koshi",
    address: "H08 Bargachi, Dharan 56700",
    telephone: "+977025533621",
    type: ["car"],
    latitude: "26.800330292278133",
    longitude: "87.2901431155778",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 118,
        station_id: 125,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 126,
    uuid: "f3e21bda-479e-4cfb-ab69-083070843489",
    name: "Hotel Arun Valley",
    city: "Dharan",
    province: "Koshi",
    address: "V85C+3J2, Bhedetar 56804",
    telephone: "+977025400009",
    type: ["car"],
    latitude: "26.85788000505003",
    longitude: "87.32196199043531",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 119,
        station_id: 126,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 127,
    uuid: "2596ecd8-ce55-4ad1-bfcc-583f031771f8",
    name: "Hotel Kanjirowa",
    city: "Dhankuta",
    province: "Koshi",
    address: "28F7+8JP, Pakhribas 56806",
    telephone: "+977026540509",
    type: ["car"],
    latitude: "27.0235761384195",
    longitude: "87.31457010612243",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 120,
        station_id: 127,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 128,
    uuid: "c2b1be02-603d-4192-b8e3-720499f11abd",
    name: "Chetan Hotel",
    city: "Mahendranagar",
    province: "Sudurpaschim",
    address: "Bhimdatta",
    telephone: "-",
    type: ["car"],
    latitude: "28.967622635921188",
    longitude: "80.18080829139451",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 121,
        station_id: 128,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 129,
    uuid: "e7c3f933-991c-4a86-b312-cb0315fdb25e",
    name: "Sipradi Trading (TATA), Mahendra Nagar",
    city: "Mahendranaagr",
    province: "Sudurpaschim",
    address: "X65C+F6R, Bhimdatta 10400",
    telephone: "+977099524515",
    type: ["car"],
    latitude: "28.958843406435594",
    longitude: "80.22053781069788",
    amenities: ["parking"],
    vendor: "tata",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 122,
        station_id: 129,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 130,
    uuid: "ca02ddb7-1102-442d-b4e3-ff001fa0e754",
    name: "APF Kalika Fuel Station GB/T",
    city: "Pokhara",
    province: "Gandaki",
    address: "Pokhara-Baglung Hwy, Pokhara 33700",
    telephone: "+977061583652",
    type: ["car"],
    latitude: "28.21876078252428",
    longitude: "83.97777172912065",
    amenities: ["restroom"],
    vendor: "nea",
    created_at: "2023-10-12T17:47:52.000000Z",
    updated_at: "2023-10-24T05:01:12.000000Z",
    image: null,
    plugs: [
      {
        id: 123,
        station_id: 130,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 124,
        station_id: 130,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 131,
    uuid: "cb296fbd-08b5-4839-96ef-1ca77f920013",
    name: "NEA Charging Station CCS, Kushma",
    city: "Baglung",
    province: "Gandaki",
    address: "Narayansthan, Kushma 33400",
    telephone: "-",
    type: ["car"],
    latitude: "28.223430651082538",
    longitude: "83.67466032342233",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2023-10-24T22:23:16.000000Z",
    updated_at: "2023-10-24T22:23:16.000000Z",
    image: null,
    plugs: [
      {
        id: 125,
        station_id: 131,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 126,
        station_id: 131,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2023-10-24T22:23:16.000000Z",
        updated_at: "2023-10-24T22:23:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 132,
    uuid: "5a544e6a-2287-4713-b8c6-f7289915afe9",
    name: "Great Wall Motor Biratnagar",
    city: "Biratnagar",
    province: "Koshi",
    address: "Bargachhi Chock, Biratnagar, Nepal",
    telephone: "+9779802317638",
    type: ["car"],
    latitude: "26.47407023167034",
    longitude: "87.2761272182843",
    amenities: ["parking"],
    vendor: "gwm",
    created_at: "2023-11-06T16:06:49.000000Z",
    updated_at: "2023-11-06T16:06:52.000000Z",
    image: null,
    plugs: [
      {
        id: 127,
        station_id: 132,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2023-11-06T16:07:17.000000Z",
        updated_at: "2023-11-06T16:07:18.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 133,
    uuid: "8c8ed672-9ef3-459a-80b2-c09c1158ac57",
    name: "NEA Charging Station CCS, Mugling",
    city: "Mugling",
    province: "Bagmati",
    address: "Bridge, Prithvi Hwy, Darechok 44206",
    telephone: null,
    type: ["car"],
    latitude: "27.85413017383783",
    longitude: "84.55810189958976",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T06:28:18.000000Z",
    updated_at: "2024-03-13T04:26:14.000000Z",
    image: null,
    plugs: [
      {
        id: 128,
        station_id: 133,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T06:28:18.000000Z",
        updated_at: "2024-01-06T06:28:18.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 129,
        station_id: 133,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T06:28:18.000000Z",
        updated_at: "2024-01-06T06:28:48.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 134,
    uuid: "fc5ed191-f796-47d6-8260-36fe22c09f9f",
    name: "NEA Charging Station CCS, Chitwan",
    city: "Chitwan",
    province: "Bagmati",
    address: "Central Bus Terminal, Bharatpur 44200",
    telephone: null,
    type: ["car"],
    latitude: "27.66735027980592",
    longitude: "84.43907921089206",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T06:31:43.000000Z",
    updated_at: "2024-01-06T06:31:43.000000Z",
    image: null,
    plugs: [
      {
        id: 130,
        station_id: 134,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T06:31:43.000000Z",
        updated_at: "2024-01-06T06:31:43.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 131,
        station_id: 134,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T06:31:43.000000Z",
        updated_at: "2024-01-06T06:31:43.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 135,
    uuid: "9b72c90a-1310-49ad-9453-f4335beca195",
    name: "NEA Charging Station CCS, Butwal",
    city: "Butwal",
    province: "Lumbini",
    address: "Butwal",
    telephone: null,
    type: ["car"],
    latitude: "27.689491988871612",
    longitude: "83.45828413944307",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:04:16.000000Z",
    updated_at: "2024-01-06T07:04:16.000000Z",
    image: null,
    plugs: [
      {
        id: 132,
        station_id: 135,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:04:16.000000Z",
        updated_at: "2024-01-06T07:04:16.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 133,
        station_id: 135,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:04:16.000000Z",
        updated_at: "2024-01-06T07:04:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 136,
    uuid: "37263fae-75c8-40ff-ac5a-e0071273172e",
    name: "NEA Charging Station GB/T, Dhangadhi Fulbari",
    city: "Dhangadhi",
    province: "Sudurpaschim",
    address: "Dhangadhi",
    telephone: null,
    type: ["car"],
    latitude: "28.599184205121084",
    longitude: "80.70680386361771",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:07:39.000000Z",
    updated_at: "2024-01-06T07:08:18.000000Z",
    image: null,
    plugs: [
      {
        id: 134,
        station_id: 136,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:07:39.000000Z",
        updated_at: "2024-01-06T07:07:39.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 135,
        station_id: 136,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:07:39.000000Z",
        updated_at: "2024-01-06T07:07:39.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 137,
    uuid: "70b24315-6f56-4e79-839f-da0d45753c23",
    name: "NEA Charging Station CCS, Dhangadhi",
    city: "Dhangadhi",
    province: "Sudurpaschim",
    address: "Santoshi Tol, Dhangadhi 10900",
    telephone: null,
    type: ["car"],
    latitude: "28.70105097197956",
    longitude: "80.57830016187734",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:10:01.000000Z",
    updated_at: "2024-01-06T07:10:01.000000Z",
    image: null,
    plugs: [
      {
        id: 136,
        station_id: 137,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:10:01.000000Z",
        updated_at: "2024-01-06T07:10:01.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 137,
        station_id: 137,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:10:01.000000Z",
        updated_at: "2024-01-06T07:10:01.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 138,
    uuid: "11c541de-5550-4ecb-9fb4-7075e3d5599b",
    name: "NEA Charging Station GB/T, Lamki",
    city: "Lamki",
    province: "Sudurpaschim",
    address: "Lamki Chuha",
    telephone: null,
    type: ["car"],
    latitude: "28.62730743863996",
    longitude: "81.15776769647478",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:16:23.000000Z",
    updated_at: "2024-01-06T07:16:23.000000Z",
    image: null,
    plugs: [
      {
        id: 138,
        station_id: 138,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:16:23.000000Z",
        updated_at: "2024-01-06T07:16:23.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
      {
        id: 139,
        station_id: 138,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:16:23.000000Z",
        updated_at: "2024-01-06T07:16:23.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 139,
    uuid: "da039a4d-9588-4a7e-b194-21a6cc58da55",
    name: "NEA Charging Station GB/T, Tourist Buspark, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "6X2C+8RH, Pokhara 33700",
    telephone: null,
    type: ["car"],
    latitude: "28.200868414837018",
    longitude: "83.97211937381338",
    amenities: ["wifi"],
    vendor: "nea",
    created_at: "2024-01-06T07:23:31.000000Z",
    updated_at: "2024-01-06T07:24:46.000000Z",
    image: null,
    plugs: [
      {
        id: 140,
        station_id: 139,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:23:31.000000Z",
        updated_at: "2024-01-06T07:23:31.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 141,
        station_id: 139,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:23:31.000000Z",
        updated_at: "2024-01-06T07:23:31.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 140,
    uuid: "586162c1-b06f-467c-921d-f37bc5c49150",
    name: "NEA Charging Station CCS, Begnas, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "538R+4C5, Lekhnath 33701",
    telephone: null,
    type: ["car"],
    latitude: "28.16577259266479",
    longitude: "84.09168964480918",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:26:36.000000Z",
    updated_at: "2024-01-06T07:26:36.000000Z",
    image: null,
    plugs: [
      {
        id: 142,
        station_id: 140,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:26:36.000000Z",
        updated_at: "2024-01-06T07:26:36.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 143,
        station_id: 140,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:26:36.000000Z",
        updated_at: "2024-01-06T07:26:36.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 141,
    uuid: "91b8a38d-cd9e-4a81-bcf3-8712892f83ed",
    name: "MOWSIE Charging Station CCS, Surunga",
    city: "Surunga",
    province: "Koshi",
    address: "Kankai",
    telephone: null,
    type: ["car"],
    latitude: "26.647474494511126",
    longitude: "87.88853925506262",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:31:45.000000Z",
    updated_at: "2024-03-23T16:49:24.000000Z",
    image: null,
    plugs: [
      {
        id: 144,
        station_id: 141,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-01-06T07:31:45.000000Z",
        updated_at: "2024-03-23T16:49:24.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 142,
    uuid: "53294c21-de2e-4c7b-8929-d24fca8c3418",
    name: "MOWSIE CCS Charger, Biratnagar",
    city: "Biratnagar",
    province: "Koshi",
    address: "F77P+J47, सडक डिभिजन, विराटनगर, Biratnagar 56613",
    telephone: null,
    type: ["car"],
    latitude: "26.46421177368932",
    longitude: "87.28531302838522",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:39:14.000000Z",
    updated_at: "2024-01-06T07:39:14.000000Z",
    image: null,
    plugs: [
      {
        id: 146,
        station_id: 142,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-01-06T07:39:14.000000Z",
        updated_at: "2024-01-06T07:40:40.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 143,
    uuid: "cdaf1494-c376-43eb-b0d1-53b298fe247b",
    name: "MOWSIE CCS, Itahari",
    city: "Itahari",
    province: "Koshi",
    address: "Taltalaiya Rd, Itahari 56705",
    telephone: null,
    type: ["car"],
    latitude: "26.661181878536027",
    longitude: "87.28205707088536",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:42:58.000000Z",
    updated_at: "2024-01-06T07:42:58.000000Z",
    image: null,
    plugs: [
      {
        id: 148,
        station_id: 143,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-01-06T07:42:58.000000Z",
        updated_at: "2024-01-06T07:42:58.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 144,
    uuid: "f16228f7-a7d6-4fe0-b1c3-48e46870e115",
    name: "NEA Charging Station GB/T, Inaruwa",
    city: "Inaruwa",
    province: "Koshi",
    address: "Inaruwa",
    telephone: null,
    type: ["car"],
    latitude: "26.60078258211841",
    longitude: "87.13773217923057",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:44:54.000000Z",
    updated_at: "2024-01-06T07:44:54.000000Z",
    image: null,
    plugs: [
      {
        id: 149,
        station_id: 144,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:44:54.000000Z",
        updated_at: "2024-01-06T07:44:54.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 150,
        station_id: 144,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:44:54.000000Z",
        updated_at: "2024-01-06T07:44:54.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 145,
    uuid: "0edb6c22-c9f6-4b6e-ad9c-41bc69cd92bd",
    name: "NEA Charging Station GB/T, Lahan",
    city: "Lahan",
    province: "Madhesh",
    address: "MGWF+4H, Madhupati 56400",
    telephone: null,
    type: ["car"],
    latitude: "26.69538028917931",
    longitude: "86.523954890735",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:49:38.000000Z",
    updated_at: "2024-01-06T07:49:38.000000Z",
    image: null,
    plugs: [
      {
        id: 152,
        station_id: 145,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:49:38.000000Z",
        updated_at: "2024-01-06T07:49:38.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 153,
        station_id: 145,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:49:38.000000Z",
        updated_at: "2024-01-06T07:49:38.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 146,
    uuid: "2c40c3c8-8386-49ae-b893-f23dabedd532",
    name: "NEA Charging Station CCS, Hariyon",
    city: "Hariyon",
    province: "Hariyon",
    address: "3HP8+HV5, Hariyon 45804",
    telephone: null,
    type: ["car"],
    latitude: "27.08649270542892",
    longitude: "85.5672801970675",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:51:55.000000Z",
    updated_at: "2024-01-06T07:52:15.000000Z",
    image: null,
    plugs: [
      {
        id: 154,
        station_id: 146,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:51:55.000000Z",
        updated_at: "2024-01-06T07:51:55.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 155,
        station_id: 146,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:51:55.000000Z",
        updated_at: "2024-01-06T07:51:55.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 147,
    uuid: "39756c88-84da-4b5e-a784-63fcf2c3eb67",
    name: "NEA Charging Station CCS, Bardibas",
    city: "Bardibas",
    province: "Madhesh",
    address: "Bardibas 45701",
    telephone: null,
    type: ["car"],
    latitude: "26.99551576735367",
    longitude: "85.89989408869032",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T07:58:37.000000Z",
    updated_at: "2024-01-06T07:58:37.000000Z",
    image: null,
    plugs: [
      {
        id: 156,
        station_id: 147,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T07:58:37.000000Z",
        updated_at: "2024-01-06T07:58:37.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 157,
        station_id: 147,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T07:58:37.000000Z",
        updated_at: "2024-01-06T07:58:37.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 199,
        station_id: 147,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-03-08T15:29:03.000000Z",
        updated_at: "2024-03-08T15:29:03.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 200,
        station_id: 147,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-03-08T15:29:03.000000Z",
        updated_at: "2024-03-08T15:29:03.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 148,
    uuid: "948ce8fa-9b06-4362-b531-c21f8abafa49",
    name: "NEA Charging Station CCS, Dharke",
    city: "Dharke",
    province: "Bagmati",
    address: "P4V7+M5, Thakre 45100",
    telephone: null,
    type: ["car"],
    latitude: "27.743627646122196",
    longitude: "85.11429139584557",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T08:02:41.000000Z",
    updated_at: "2024-01-06T08:02:41.000000Z",
    image: null,
    plugs: [
      {
        id: 158,
        station_id: 148,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T08:02:41.000000Z",
        updated_at: "2024-01-06T08:02:41.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 159,
        station_id: 148,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T08:02:41.000000Z",
        updated_at: "2024-01-06T08:02:41.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 149,
    uuid: "7f0a6733-d6c5-4d5a-abcc-d77e586a9ce0",
    name: "NEA Charging Station CCS, Kharipati",
    city: "Bhaktapur",
    province: "Bagmati",
    address: "Kharipati",
    telephone: null,
    type: ["car"],
    latitude: "27.68120978590557",
    longitude: "85.45025786272402",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T08:08:04.000000Z",
    updated_at: "2024-01-06T08:08:04.000000Z",
    image: null,
    plugs: [
      {
        id: 160,
        station_id: 149,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T08:08:04.000000Z",
        updated_at: "2024-01-06T08:08:04.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 161,
        station_id: 149,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T08:08:04.000000Z",
        updated_at: "2024-01-06T08:08:04.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 150,
    uuid: "13695c0e-89a0-4f0a-8036-8bb37bfbfb2e",
    name: "NEA Charging Station CCS, Samakhushi",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Taalim Kendra Bus Stop, Kathmandu 44600",
    telephone: null,
    type: ["car"],
    latitude: "27.736042716059544",
    longitude: "85.32262971149595",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T08:10:05.000000Z",
    updated_at: "2024-01-06T08:10:05.000000Z",
    image: null,
    plugs: [
      {
        id: 162,
        station_id: 150,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T08:10:05.000000Z",
        updated_at: "2024-01-06T08:10:05.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 163,
        station_id: 150,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T08:10:05.000000Z",
        updated_at: "2024-01-06T08:10:05.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 151,
    uuid: "1c7c33a1-8e77-47ef-a840-6c779a536b45",
    name: "NEA Charging Station CCS and GB/T, Balkhu",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Sajha Petrol Pump, Balkhu",
    telephone: null,
    type: ["car"],
    latitude: "27.68548430406887",
    longitude: "85.29942276112442",
    amenities: ["parking"],
    vendor: "nea",
    created_at: "2024-01-06T08:14:50.000000Z",
    updated_at: "2024-01-06T08:17:33.000000Z",
    image: null,
    plugs: [
      {
        id: 166,
        station_id: 151,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T08:14:50.000000Z",
        updated_at: "2024-01-06T08:14:50.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 167,
        station_id: 151,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T08:14:50.000000Z",
        updated_at: "2024-01-06T08:14:50.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 170,
        station_id: 151,
        plug: "gbt",
        power: "120Kw",
        type: "DC",
        created_at: "2024-01-06T08:17:33.000000Z",
        updated_at: "2024-01-06T08:17:33.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
      {
        id: 171,
        station_id: 151,
        plug: "gbt",
        power: "22Kw",
        type: "AC",
        created_at: "2024-01-06T08:17:33.000000Z",
        updated_at: "2024-01-06T08:17:33.000000Z",
        count: 1,
        icon: "gbt-ac",
      },
    ],
  },
  {
    id: 153,
    uuid: "09c37089-ef3a-46a4-a1d2-b91ed3ada04e",
    name: "Metro Durbar",
    city: "Dang",
    province: "Lumbini",
    address: "opposite side of high court tulsipur 6",
    telephone: "+9779857860015",
    type: ["car"],
    latitude: "28.12740062441808",
    longitude: "82.29576905575951",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2024-02-27T14:52:45.000000Z",
    updated_at: "2024-02-27T14:52:45.000000Z",
    image: null,
    plugs: [
      {
        id: 173,
        station_id: 153,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2024-02-27T14:52:45.000000Z",
        updated_at: "2024-02-27T14:52:45.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 154,
    uuid: "0194f09a-44a3-47d0-b929-b1a4daee8e80",
    name: "Hotel Pauwa, Bhaluwang",
    city: "Bhaluwang",
    province: "Lumbini",
    address: "RQQ3+CMR, Lalmatiya 22404",
    telephone: "+977082580312",
    type: ["car"],
    latitude: "27.84032476860082",
    longitude: "82.75403090370484",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-02-27T16:03:06.000000Z",
    updated_at: "2024-03-21T14:56:43.000000Z",
    image: null,
    plugs: [
      {
        id: 174,
        station_id: 154,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-02-27T16:03:06.000000Z",
        updated_at: "2024-02-27T16:03:06.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 155,
    uuid: "0b719e40-5318-44fb-a2fc-71b516b60f3c",
    name: "Mahendra Police Club",
    city: "Kathmandu",
    province: "Bagmati",
    address: "P829+W5C, Kathmandu 44600, Nepal",
    telephone: null,
    type: ["car"],
    latitude: "27.703106533170015",
    longitude: "85.31809893302768",
    amenities: ["parking"],
    vendor: null,
    created_at: "2024-03-06T12:36:26.000000Z",
    updated_at: "2024-03-06T12:36:26.000000Z",
    image: null,
    plugs: [
      {
        id: 175,
        station_id: 155,
        plug: "ccs2",
        power: "120Kw",
        type: "DC",
        created_at: "2024-03-06T12:36:26.000000Z",
        updated_at: "2024-03-06T12:36:26.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 176,
        station_id: 155,
        plug: "ccs2",
        power: "22Kw",
        type: "AC",
        created_at: "2024-03-06T12:36:26.000000Z",
        updated_at: "2024-03-06T12:36:26.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 156,
    uuid: "d65fa7be-87dd-4b62-a197-fc74d9ad11ce",
    name: "Plugin Automobiles",
    city: "Pokhara",
    province: "Gandaki",
    address: "Uttam Chowk, Pokhara 33700",
    telephone: "+9779801097284",
    type: ["car"],
    latitude: "28.2068611692237",
    longitude: "83.99713659467342",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2024-03-08T12:59:15.000000Z",
    updated_at: "2024-03-08T12:59:15.000000Z",
    image: null,
    plugs: [
      {
        id: 178,
        station_id: 156,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T12:59:15.000000Z",
        updated_at: "2024-03-08T12:59:15.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 187,
        station_id: 156,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:28:08.000000Z",
        updated_at: "2024-03-08T13:28:08.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 157,
    uuid: "d533485d-5c6f-4e52-808a-98a02c543a9e",
    name: "Khareha Om Park, Kushma, Parvat",
    city: "Kushma",
    province: "Gandaki",
    address: "6MFV+254, Kushma 33400",
    telephone: "9857630612",
    type: ["car"],
    latitude: "28.22268311138723",
    longitude: "83.69301737733579",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2024-03-08T13:03:23.000000Z",
    updated_at: "2024-03-08T13:07:03.000000Z",
    image: null,
    plugs: [
      {
        id: 179,
        station_id: 157,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:03:23.000000Z",
        updated_at: "2024-03-08T13:03:23.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 158,
    uuid: "a3bd9b13-bd30-43fe-85e7-5404d9aa04f5",
    name: "Namaste Village Resort",
    city: "Nepalgunj",
    province: "5",
    address: "Ganapur, Nepalgunj 21900",
    telephone: "081403061",
    type: ["car"],
    latitude: "28.118293022102566",
    longitude: "81.66028906994319",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "byd",
    created_at: "2024-03-08T13:04:56.000000Z",
    updated_at: "2024-03-08T13:04:56.000000Z",
    image: null,
    plugs: [
      {
        id: 180,
        station_id: 158,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:04:56.000000Z",
        updated_at: "2024-03-08T13:04:56.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 159,
    uuid: "e8f63f97-0af5-4da9-bf27-8972ed6f3dfd",
    name: "BYD Showroom, Narayanchaur",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Kathmandu 44600",
    telephone: "015912037",
    type: ["car"],
    latitude: "27.714332454326634",
    longitude: "85.3247722711438",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2024-03-08T13:06:41.000000Z",
    updated_at: "2024-03-08T13:06:41.000000Z",
    image: null,
    plugs: [
      {
        id: 181,
        station_id: 159,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:06:41.000000Z",
        updated_at: "2024-03-08T13:06:41.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 160,
    uuid: "3179a29d-6a3a-431c-a272-20ec28da27cf",
    name: "BYD Service center, Guheshwari",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Kumarigal, Kathmandu 44600",
    telephone: "9802396103",
    type: ["car"],
    latitude: "27.712457919680723",
    longitude: "85.35592200391444",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2024-03-08T13:08:20.000000Z",
    updated_at: "2024-03-08T13:08:20.000000Z",
    image: null,
    plugs: [
      {
        id: 182,
        station_id: 160,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:08:20.000000Z",
        updated_at: "2024-03-08T13:08:20.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 161,
    uuid: "fef93d61-6d1c-4911-a6e6-9db14ea363b3",
    name: "Hotel Western Palace",
    city: "Tulsipur",
    province: "Lumbini",
    address: "Tulsipur 22412",
    telephone: "082522857",
    type: ["car"],
    latitude: "28.132606261688505",
    longitude: "82.30136068693193",
    amenities: ["wifi", "parking", "food", "coffee", "restroom"],
    vendor: "byd",
    created_at: "2024-03-08T13:12:33.000000Z",
    updated_at: "2024-03-08T13:12:33.000000Z",
    image: null,
    plugs: [
      {
        id: 183,
        station_id: 161,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:12:33.000000Z",
        updated_at: "2024-03-08T13:12:33.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 162,
    uuid: "9b79af48-2a8d-4c94-a81d-3a0b0fb5abe9",
    name: "BYD Lalitpur, Eco Drive Automobiles Pvt Ltd",
    city: "Lalitpur",
    province: "Bagmati",
    address: "Pulchowk Rd, Lalitpur 44600",
    telephone: "015705505",
    type: ["car"],
    latitude: "27.682559547504358",
    longitude: "85.31805608537086",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2024-03-08T13:23:44.000000Z",
    updated_at: "2024-03-08T13:23:44.000000Z",
    image: null,
    plugs: [
      {
        id: 185,
        station_id: 162,
        plug: "ccs2",
        power: "40Kw",
        type: "AC",
        created_at: "2024-03-08T13:23:44.000000Z",
        updated_at: "2024-03-08T13:23:44.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 196,
        station_id: 162,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:49:15.000000Z",
        updated_at: "2024-03-08T13:49:15.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 163,
    uuid: "b992313a-973e-4c15-af1a-16bbc154fc07",
    name: "Ram Janaki Traders",
    city: "Janakpur",
    province: "Madhesh",
    address: "QW5H+P8Q, Janakpur 45600",
    telephone: "9824705794",
    type: ["car"],
    latitude: "26.759491315312165",
    longitude: "85.9288285523328",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2024-03-08T13:27:48.000000Z",
    updated_at: "2024-03-08T13:27:48.000000Z",
    image: null,
    plugs: [
      {
        id: 186,
        station_id: 163,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:27:48.000000Z",
        updated_at: "2024-03-08T13:27:48.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 164,
    uuid: "309981b1-ed88-493f-86bc-01df4b1e162c",
    name: "Team Eleven EV World Pvt Ltd, BYD",
    city: "Nepalgunj",
    province: "5",
    address: "Nepalgunj 21900",
    telephone: "9857821410",
    type: ["car"],
    latitude: "28.113509501716536",
    longitude: "81.65884608360685",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2024-03-08T13:33:50.000000Z",
    updated_at: "2024-03-08T13:33:50.000000Z",
    image: null,
    plugs: [
      {
        id: 188,
        station_id: 164,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:33:50.000000Z",
        updated_at: "2024-03-08T13:33:50.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 165,
    uuid: "318f894a-10c8-4908-85a8-4c62a2438700",
    name: "Kwality Beach Resort, Mulkot",
    city: "Mulkot",
    province: "Bagmati",
    address: "B.P Highway, Mulkot",
    telephone: "9865369093",
    type: ["car"],
    latitude: "27.399947208940542",
    longitude: "85.92445846670022",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "byd",
    created_at: "2024-03-08T13:35:40.000000Z",
    updated_at: "2024-03-08T13:35:40.000000Z",
    image: null,
    plugs: [
      {
        id: 189,
        station_id: 165,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:35:40.000000Z",
        updated_at: "2024-03-08T13:35:40.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 166,
    uuid: "dde726b2-6c81-42bf-b79e-c405a5e1ee1b",
    name: "BYD Service Center, Kathmandu",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Kathmandu 44600",
    telephone: "9802331177",
    type: ["car"],
    latitude: "27.712761152723775",
    longitude: "85.35598104705572",
    amenities: ["parking", "restroom"],
    vendor: "byd",
    created_at: "2024-03-08T13:37:52.000000Z",
    updated_at: "2024-03-08T13:37:52.000000Z",
    image: null,
    plugs: [
      {
        id: 190,
        station_id: 166,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:37:52.000000Z",
        updated_at: "2024-03-08T13:37:52.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 167,
    uuid: "15aa1533-a6cd-4c5b-8108-f0a264047167",
    name: "Hakim Chowk, Bharatpur",
    city: "Chitwan",
    province: "Bagmati",
    address: "Hakim Chowk, Bharatpur 44207",
    telephone: "9801368497",
    type: ["car"],
    latitude: "27.671578987484367",
    longitude: "84.43396127546322",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2024-03-08T13:45:07.000000Z",
    updated_at: "2024-03-08T13:45:07.000000Z",
    image: null,
    plugs: [
      {
        id: 192,
        station_id: 167,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:45:07.000000Z",
        updated_at: "2024-03-08T13:45:07.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 168,
    uuid: "3166431d-0d78-45e7-b84e-7b20eacb107e",
    name: "Butwal Enlighten motors, BYD",
    city: "Butwal",
    province: "Lumbini",
    address: "Butwal 32907",
    telephone: "9857088804",
    type: ["car"],
    latitude: "27.67704227926995",
    longitude: "83.46485757284043",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2024-03-08T13:47:06.000000Z",
    updated_at: "2024-03-08T13:47:06.000000Z",
    image: null,
    plugs: [
      {
        id: 193,
        station_id: 168,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:47:06.000000Z",
        updated_at: "2024-03-08T13:47:06.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 169,
    uuid: "97a78e80-73dd-427d-9d3d-8f4b0696ad18",
    name: "Nataraj Auto Pvt Ltd, BYD",
    city: "Birtamode",
    province: "1",
    address: "Birtamode 57204",
    telephone: "9842752415",
    type: ["car"],
    latitude: "26.64028897656544",
    longitude: "87.9786154253969",
    amenities: ["parking"],
    vendor: "byd",
    created_at: "2024-03-08T13:48:39.000000Z",
    updated_at: "2024-03-08T13:48:39.000000Z",
    image: null,
    plugs: [
      {
        id: 194,
        station_id: 169,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-08T13:48:39.000000Z",
        updated_at: "2024-03-08T13:48:39.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 170,
    uuid: "2dc8bbf6-cb9d-4c60-8902-103ff3bc52bc",
    name: "Indrayani EV Charging Station",
    city: "Dhading",
    province: "Bagmati",
    address: "Belkhu mod (Naya Pul) , Galchhi-4, Dhading",
    telephone: "9849274385",
    type: ["car"],
    latitude: "27.81582406067107",
    longitude: "84.95619760458322",
    amenities: ["parking"],
    vendor: null,
    created_at: "2024-03-08T15:27:27.000000Z",
    updated_at: "2024-03-08T15:27:27.000000Z",
    image: null,
    plugs: [
      {
        id: 198,
        station_id: 170,
        plug: "gbt",
        power: "40Kw",
        type: "DC",
        created_at: "2024-03-08T15:27:27.000000Z",
        updated_at: "2024-03-08T15:27:27.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 171,
    uuid: "d108baa2-3125-4b66-baec-73306a3f398c",
    name: "Meghauli Serai, A Taj Safari, Chitwan",
    city: "Chitwan",
    province: "Bagmati",
    address:
      "Ward no 1 Narayani Nager Palika Chitwan National Park Nepal, Meghauli 44207",
    telephone: "9851218500",
    type: ["car"],
    latitude: "27.56982785070489",
    longitude: "84.20147026771306",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2024-03-08T15:31:25.000000Z",
    updated_at: "2024-03-08T15:31:25.000000Z",
    image: null,
    plugs: [
      {
        id: 201,
        station_id: 171,
        plug: "ccs2",
        power: "7Kw",
        type: "AC",
        created_at: "2024-03-08T15:31:25.000000Z",
        updated_at: "2024-03-08T15:31:25.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 172,
    uuid: "c6d382f1-d930-4737-9c74-fc665319a6ba",
    name: "Barahi Jungle Lodge",
    city: "Chitwan",
    province: "Bagmati",
    address: "H59W+RP Andrauli West, Narayani 97761",
    telephone: "9851355826",
    type: ["car"],
    latitude: "27.56971229266913",
    longitude: "84.19520287830065",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2024-03-08T15:34:46.000000Z",
    updated_at: "2024-03-08T15:34:46.000000Z",
    image: null,
    plugs: [
      {
        id: 202,
        station_id: 172,
        plug: "ccs2",
        power: null,
        type: null,
        created_at: "2024-03-08T15:34:46.000000Z",
        updated_at: "2024-03-08T15:34:46.000000Z",
        count: 1,
        icon: "ccs2",
      },
    ],
  },
  {
    id: 173,
    uuid: "9b3b95c6-870b-46f6-89fe-e5c38d58b512",
    name: "Bulbul Farm Retreat",
    city: "Palpa",
    province: "Lumbini",
    address: "Bartung - Tansen Rd, Tansen 32500",
    telephone: "9766225686",
    type: ["car"],
    latitude: "27.856467054779355",
    longitude: "83.55237344899913",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2024-03-08T15:38:17.000000Z",
    updated_at: "2024-03-08T15:38:17.000000Z",
    image: null,
    plugs: [
      {
        id: 203,
        station_id: 173,
        plug: "ccs2",
        power: null,
        type: "DC",
        created_at: "2024-03-08T15:38:17.000000Z",
        updated_at: "2024-03-08T15:38:17.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 174,
    uuid: "f034b7f8-7be1-4107-a325-e0e89ed40eb6",
    name: "Hotel Welcome, Janakpur",
    city: "Janakpur",
    province: "Madhesh",
    address: "Shiv chowk, Janakpur 45600",
    telephone: "041590646",
    type: ["car"],
    latitude: "26.73018967519929",
    longitude: "85.92983480802702",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: null,
    created_at: "2024-03-09T17:46:47.000000Z",
    updated_at: "2024-03-09T17:46:47.000000Z",
    image: null,
    plugs: [
      {
        id: 204,
        station_id: 174,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-09T17:46:47.000000Z",
        updated_at: "2024-03-09T17:46:47.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 175,
    uuid: "a82d7fd9-6a51-48da-b823-9fb52ea1ef79",
    name: "Chapur Charging Station",
    city: "Chapur",
    province: "Madhesh",
    address: "496F+Q3G, Chandrapur 44515",
    telephone: null,
    type: ["car"],
    latitude: "27.1120661",
    longitude: "85.3727899",
    amenities: ["parking", "restroom"],
    vendor: null,
    created_at: "2024-03-15T15:19:59.000000Z",
    updated_at: "2024-03-15T15:26:03.000000Z",
    image: null,
    plugs: [
      {
        id: 205,
        station_id: 175,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-03-15T15:19:59.000000Z",
        updated_at: "2024-03-15T15:19:59.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 206,
        station_id: 175,
        plug: "gbt",
        power: "60Kw",
        type: "DC",
        created_at: "2024-03-15T15:19:59.000000Z",
        updated_at: "2024-03-15T15:19:59.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 176,
    uuid: "6a8590b5-ea2b-460a-851a-8797b5ae280f",
    name: "Townhall Party Palace and Hotel Peace Garden",
    city: "Birgunj",
    province: "Madhesh",
    address: "44300, Birgunj",
    telephone: "051418169",
    type: ["car"],
    latitude: "27.01363072828277",
    longitude: "84.87032895808805",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T05:24:06.000000Z",
    updated_at: "2024-03-16T05:24:06.000000Z",
    image: null,
    plugs: [
      {
        id: 207,
        station_id: 176,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:24:06.000000Z",
        updated_at: "2024-03-16T05:24:06.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 177,
    uuid: "daccb453-71b9-4fb2-987f-0b8061b796f5",
    name: "Crown Restaurant & Lodge",
    city: "Satbariya",
    province: "Lumbini",
    address: "X6C6+H9M, Mahendra Hwy, Satbariya 22400",
    telephone: "9857878200",
    type: ["car"],
    latitude: "27.971514940775904",
    longitude: "82.21092419985885",
    amenities: ["wifi", "parking", "food", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T05:26:00.000000Z",
    updated_at: "2024-03-16T05:26:00.000000Z",
    image: null,
    plugs: [
      {
        id: 208,
        station_id: 177,
        plug: "ccs2",
        power: "7.2",
        type: "AC",
        created_at: "2024-03-16T05:26:00.000000Z",
        updated_at: "2024-03-16T05:26:00.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 178,
    uuid: "39b41559-e625-4b99-b64f-4d96c1ccfffe",
    name: "Hotel Durbar, Supadeurali, Arghakhanchi,",
    city: "Arghakhanchi",
    province: "Lumbini",
    address: "Sitganga - 4 , Bhedamare, Supadeurali",
    telephone: "9857055554",
    type: ["car"],
    latitude: "27.838183693109432",
    longitude: "83.07241401800297",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T05:30:20.000000Z",
    updated_at: "2024-03-16T05:30:20.000000Z",
    image: null,
    plugs: [
      {
        id: 209,
        station_id: 178,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:30:20.000000Z",
        updated_at: "2024-03-16T05:30:20.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 179,
    uuid: "24eb480b-187a-4ab9-8b90-082b0c486860",
    name: "Hotel Suvidha",
    city: "Attariya",
    province: "Sudurpaschim",
    address: "RH76+CR4, Attariya 10900",
    telephone: "9858427120",
    type: ["car"],
    latitude: "28.81356214289089",
    longitude: "80.56205602888197",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T05:32:11.000000Z",
    updated_at: "2024-03-16T05:32:11.000000Z",
    image: null,
    plugs: [
      {
        id: 210,
        station_id: 179,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:32:11.000000Z",
        updated_at: "2024-03-16T05:32:11.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 180,
    uuid: "125c9501-f192-4c10-ad7f-6154c6628e3d",
    name: "London Bridge Hotel",
    city: "Siddharthanagar",
    province: "Lumbini",
    address: "FFM2+J9P, Siddharthanagar",
    telephone: null,
    type: ["car"],
    latitude: "27.48432176398286",
    longitude: "83.45101863125423",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T05:34:37.000000Z",
    updated_at: "2024-03-16T05:34:37.000000Z",
    image: null,
    plugs: [
      {
        id: 211,
        station_id: 180,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:34:37.000000Z",
        updated_at: "2024-03-16T05:34:37.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 181,
    uuid: "17b9ef7d-f100-4909-8aa2-d69285e5d189",
    name: "Babai Resort Pvt. Ltd",
    city: "Thakudwara",
    province: "Lumbini",
    address: "National Park, Thakudwara 21800",
    telephone: "9851031791",
    type: ["car"],
    latitude: "28.44530289263624",
    longitude: "81.23832616211507",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T05:36:16.000000Z",
    updated_at: "2024-03-16T05:36:16.000000Z",
    image: null,
    plugs: [
      {
        id: 212,
        station_id: 181,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:36:16.000000Z",
        updated_at: "2024-03-16T05:36:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 182,
    uuid: "d39de6eb-81d5-4fdb-a3a6-52f338ced460",
    name: "Gateway Himalaya Resort",
    city: "Besisahar",
    province: "Gandaki",
    address: "Dumre-Beshisahar-Chame Hwy, Besisahar 33600",
    telephone: "9856014102",
    type: ["car"],
    latitude: "28.234906891272264",
    longitude: "84.37604179369362",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T05:40:20.000000Z",
    updated_at: "2024-03-16T05:40:20.000000Z",
    image: null,
    plugs: [
      {
        id: 213,
        station_id: 182,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:40:20.000000Z",
        updated_at: "2024-03-16T05:40:20.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 183,
    uuid: "8abccaef-1e6a-4818-a49f-3ef0f8f13fea",
    name: "Sipradi Trading Pvt. Ltd, Patthardada",
    city: "Tillotama",
    province: "Lumbini",
    address: "HFMC+FPR, Patthardada 32903",
    telephone: "071561033",
    type: ["car"],
    latitude: "27.584071529557225",
    longitude: "83.47179688610117",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T05:43:37.000000Z",
    updated_at: "2024-03-16T05:43:37.000000Z",
    image: null,
    plugs: [
      {
        id: 214,
        station_id: 183,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T05:43:37.000000Z",
        updated_at: "2024-03-16T05:43:37.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 184,
    uuid: "b55c58bb-af12-47d0-a654-7414302193b1",
    name: "Hotel Nirvana by Luxury International",
    city: "Bhairahawa",
    province: "Lumbini",
    address: "Paklihawa Road, Siddharthanagar (Bhairahawa)",
    telephone: "071570837",
    type: ["car"],
    latitude: "27.501521366756748",
    longitude: "83.44863884747689",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:10:19.000000Z",
    updated_at: "2024-03-16T07:10:19.000000Z",
    image: null,
    plugs: [
      {
        id: 215,
        station_id: 184,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:10:19.000000Z",
        updated_at: "2024-03-16T07:10:19.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 185,
    uuid: "6edfcb34-f022-405c-8bb6-f11ebe5e31a3",
    name: "Sipradi Service Center - Bhaktapur Branch",
    city: "Bhaktapur",
    province: "Bagmati",
    address: "Araniko Highway, Suryabinayak 44800",
    telephone: "9801008333",
    type: ["car"],
    latitude: "27.668647417429632",
    longitude: "85.41316221398546",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:13:11.000000Z",
    updated_at: "2024-03-21T15:19:34.000000Z",
    image: null,
    plugs: [
      {
        id: 216,
        station_id: 185,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:13:11.000000Z",
        updated_at: "2024-03-16T07:13:11.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 287,
        station_id: 185,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T15:19:34.000000Z",
        updated_at: "2024-03-21T15:19:34.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 186,
    uuid: "af5b1586-d57a-4048-8f03-34a8dc061c9f",
    name: "Sipradi Trading P. Ltd, Bhaktapur",
    city: "Bhaktapur",
    province: "Bagmati",
    address: "Madhyapur Thimi 00977",
    telephone: "015914771",
    type: ["car"],
    latitude: "27.67501400919583",
    longitude: "85.39476443749403",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:14:46.000000Z",
    updated_at: "2024-03-16T07:14:46.000000Z",
    image: null,
    plugs: [
      {
        id: 217,
        station_id: 186,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:14:46.000000Z",
        updated_at: "2024-03-16T07:14:46.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 187,
    uuid: "2ac2263a-ac7c-48ef-a604-5ced00211b31",
    name: "Hotel Delux Inn",
    city: "Lalmatiya",
    province: "Lumbini",
    address: "Lalmatiya 22404",
    telephone: "082590458",
    type: ["car"],
    latitude: "27.846695477792284",
    longitude: "82.7001391602194",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:16:30.000000Z",
    updated_at: "2024-03-16T07:16:30.000000Z",
    image: null,
    plugs: [
      {
        id: 218,
        station_id: 187,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:16:30.000000Z",
        updated_at: "2024-03-16T07:16:30.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 188,
    uuid: "f3e5a313-7532-4cb9-9260-8a1ca80ede5d",
    name: "Nepalirika Hotel, Haraicha",
    city: "Haraicha",
    province: "Koshi",
    address: "M97H+47G, Koshi Haraicha 56600",
    telephone: "021545212",
    type: ["car"],
    latitude: "26.66432931980155",
    longitude: "87.38052150673042",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:18:10.000000Z",
    updated_at: "2024-03-16T07:18:10.000000Z",
    image: null,
    plugs: [
      {
        id: 219,
        station_id: 188,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:18:10.000000Z",
        updated_at: "2024-03-16T07:18:10.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 189,
    uuid: "7d54b3f1-3a85-47e2-bc89-6bfa88d74976",
    name: "Prime Auto Traders",
    city: "Birgunj",
    province: "Madhesh",
    address: "2VJR+M66, Birgunj 44400",
    telephone: "051691962",
    type: ["car"],
    latitude: "27.0317134983229",
    longitude: "84.89051278434555",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:21:45.000000Z",
    updated_at: "2024-03-16T07:21:45.000000Z",
    image: null,
    plugs: [
      {
        id: 220,
        station_id: 189,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:21:45.000000Z",
        updated_at: "2024-03-16T07:21:45.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 190,
    uuid: "4e8abbf5-51ac-40ba-b1d1-92e71eebe0d2",
    name: "Sipradi Trading Pvt. Ltd, Birgunj",
    city: "Birgunj",
    province: "Madhesh",
    address: "2VVX+Q94, Birgunj",
    telephone: "051411174",
    type: ["car"],
    latitude: "27.043878846528006",
    longitude: "84.89927940496766",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:22:47.000000Z",
    updated_at: "2024-03-16T07:22:47.000000Z",
    image: null,
    plugs: [
      {
        id: 221,
        station_id: 190,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:22:47.000000Z",
        updated_at: "2024-03-16T07:22:47.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 191,
    uuid: "df421254-8d24-41f8-9b84-38c70a6fb918",
    name: "Siddhartha Lumbini Green Resort",
    city: "Lumbini",
    province: "Lumbini",
    address: "Shivaraj-2 Chandrauta",
    telephone: "9847419260",
    type: ["car"],
    latitude: "27.655351239564038",
    longitude: "82.83934523579416",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:25:39.000000Z",
    updated_at: "2024-03-16T07:25:39.000000Z",
    image: null,
    plugs: [
      {
        id: 222,
        station_id: 191,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:25:39.000000Z",
        updated_at: "2024-03-16T07:25:39.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 192,
    uuid: "f2df3911-0995-4070-88fd-8cc16d40d817",
    name: "Hotel Tarai Inn",
    city: "Chandrapur",
    province: "Madhesh",
    address: "Chapur Chowk, Chandrapur 44515",
    telephone: "055540600",
    type: ["car"],
    latitude: "27.1209911891066",
    longitude: "85.35624667174301",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:27:00.000000Z",
    updated_at: "2024-03-16T07:27:00.000000Z",
    image: null,
    plugs: [
      {
        id: 223,
        station_id: 192,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:27:00.000000Z",
        updated_at: "2024-03-16T07:27:00.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 193,
    uuid: "04ccec59-22bb-4944-b98b-1f15691d2305",
    name: "Hotel Ambrosia, Charikot",
    city: "Charikot",
    province: "Bagmati",
    address: "Bhimeshwor Municipality 45500",
    telephone: "049421600",
    type: ["car"],
    latitude: "27.663826356751258",
    longitude: "86.04785290519658",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:29:25.000000Z",
    updated_at: "2024-03-16T07:29:25.000000Z",
    image: null,
    plugs: [
      {
        id: 224,
        station_id: 193,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:29:25.000000Z",
        updated_at: "2024-03-16T07:29:25.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 194,
    uuid: "c76e3d2f-3ac1-4438-a60b-2229941accce",
    name: "Sipradi Trading Pvt Ltd, Charikot",
    city: "Charikot",
    province: "Bagmati",
    address: "M2HF+QM5, Bhimeshwor Municipality 45500",
    telephone: null,
    type: ["car"],
    latitude: "27.67944186833564",
    longitude: "86.02431478414309",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:30:40.000000Z",
    updated_at: "2024-03-16T07:30:40.000000Z",
    image: null,
    plugs: [
      {
        id: 225,
        station_id: 194,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:30:40.000000Z",
        updated_at: "2024-03-16T07:30:40.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 195,
    uuid: "753cbefb-a8d7-4f46-bf58-188b3ddd45fc",
    name: "Belaka Rivers Beach Resort",
    city: "Belaka",
    province: "Koshi",
    address: "Sombare, 56300",
    telephone: "9863586091",
    type: ["car"],
    latitude: "26.841097450852367",
    longitude: "87.1433128998478",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:32:18.000000Z",
    updated_at: "2024-03-16T07:32:18.000000Z",
    image: null,
    plugs: [
      {
        id: 226,
        station_id: 195,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:32:18.000000Z",
        updated_at: "2024-03-16T07:32:18.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 196,
    uuid: "516eec04-a388-4163-88f8-32847f927bc4",
    name: "Siraichuli Hotel",
    city: "Bharatpur",
    province: "Bagmati",
    address: "E W Lions Chowk, Mahendra Hwy, Bharatpur 44207",
    telephone: "056590005",
    type: ["car"],
    latitude: "27.688275570157636",
    longitude: "84.4254819156685",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:33:32.000000Z",
    updated_at: "2024-03-16T07:33:32.000000Z",
    image: null,
    plugs: [
      {
        id: 227,
        station_id: 196,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:33:32.000000Z",
        updated_at: "2024-03-16T07:33:32.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 197,
    uuid: "578ef159-1495-4307-9e6f-4fb1d4356d00",
    name: "Sipradi Bharatpur Chitwan (SH Motors)",
    city: "Bharatpur",
    province: "Bagmati",
    address: "MCFM+472, Mini Ring Rd, Bharatpur 44207",
    telephone: null,
    type: ["car"],
    latitude: "27.672755305531137",
    longitude: "84.43320812444595",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:34:47.000000Z",
    updated_at: "2024-03-21T14:55:24.000000Z",
    image: null,
    plugs: [
      {
        id: 228,
        station_id: 197,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:34:47.000000Z",
        updated_at: "2024-03-16T07:34:47.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 283,
        station_id: 197,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T14:55:24.000000Z",
        updated_at: "2024-03-21T14:55:24.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 198,
    uuid: "0b9af2e1-b0d8-41a7-b740-c023fcd2ac1e",
    name: "Hotel Sunlight Pvt.Ltd",
    city: "Dhangadhi",
    province: "Sudurpaschim",
    address: "PH3P+V4Q, Main Road, Dhangadhi 10900",
    telephone: "091524176",
    type: ["car"],
    latitude: "28.704749386246903",
    longitude: "80.58535173657548",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:36:07.000000Z",
    updated_at: "2024-03-16T07:36:07.000000Z",
    image: null,
    plugs: [
      {
        id: 229,
        station_id: 198,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:36:07.000000Z",
        updated_at: "2024-03-16T07:36:07.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 199,
    uuid: "1cbb2c64-0f6e-4994-8bfb-c73bbd9b6ab8",
    name: "Sahara Resort",
    city: "Thaha",
    province: "Bagmati",
    address: "J3CP+92J, Tribhuvan Highway, Thaha 44100",
    telephone: "9855070361",
    type: ["car"],
    latitude: "27.62104679201297",
    longitude: "85.08503214592683",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:38:20.000000Z",
    updated_at: "2024-03-16T07:38:20.000000Z",
    image: null,
    plugs: [
      {
        id: 230,
        station_id: 199,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:38:20.000000Z",
        updated_at: "2024-03-16T07:38:20.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 200,
    uuid: "cad02308-ae72-4589-9f3b-79f9f6283d59",
    name: "Hill Top Restaurant",
    city: "Dhading",
    province: "Bagmati",
    address: "Golghar, Malekhu, Dhading",
    telephone: "9851188459",
    type: ["car"],
    latitude: "27.81005344029913",
    longitude: "84.82492333045559",
    amenities: ["wifi", "parking", "food", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:41:32.000000Z",
    updated_at: "2024-03-16T07:54:17.000000Z",
    image: null,
    plugs: [
      {
        id: 231,
        station_id: 200,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:41:32.000000Z",
        updated_at: "2024-03-16T07:41:32.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 201,
    uuid: "df9a226f-0934-4cfd-9a4f-a646ac7e9ffa",
    name: "Navadurga Technology",
    city: "Dhangadhi",
    province: "Sudurpaschim",
    address: "Attariya 10900",
    telephone: null,
    type: ["car"],
    latitude: "28.74395688462778",
    longitude: "80.56588779681542",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:44:29.000000Z",
    updated_at: "2024-03-16T07:44:29.000000Z",
    image: null,
    plugs: [
      {
        id: 232,
        station_id: 201,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:44:29.000000Z",
        updated_at: "2024-03-16T07:44:29.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 202,
    uuid: "ebbf90eb-b5e4-41af-91bf-361235747383",
    name: "Hotel Mustang Plaza",
    city: "Dumre",
    province: "Bagmati",
    address: "Dumre, 04, Bandipur",
    telephone: "065580472",
    type: ["car"],
    latitude: "27.958438167384013",
    longitude: "84.41506358341445",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:46:14.000000Z",
    updated_at: "2024-03-16T07:46:14.000000Z",
    image: null,
    plugs: [
      {
        id: 233,
        station_id: 202,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:46:14.000000Z",
        updated_at: "2024-03-16T07:46:14.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 203,
    uuid: "2e19ad65-2767-4221-9a86-d9d53845f4ff",
    name: "Majestic Hotel Pvt.Ltd",
    city: "Ghorahi",
    province: "Lumbini",
    address: "Short root, Ghorahi 22400",
    telephone: "9857862165",
    type: ["car"],
    latitude: "28.034551852531518",
    longitude: "82.48518954676244",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:47:43.000000Z",
    updated_at: "2024-03-16T07:47:43.000000Z",
    image: null,
    plugs: [
      {
        id: 234,
        station_id: 203,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:47:43.000000Z",
        updated_at: "2024-03-16T07:47:43.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 204,
    uuid: "a821c8aa-d4a5-4140-950f-406fd1590560",
    name: "Sipradi Trading Pvt Ltd",
    city: "Ghorahi",
    province: "Lumbini",
    address: "2CMW+488, F015, Ghorahi 22400",
    telephone: "9801988960",
    type: ["car"],
    latitude: "28.032965764729752",
    longitude: "82.44590605517216",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:48:58.000000Z",
    updated_at: "2024-03-16T07:48:58.000000Z",
    image: null,
    plugs: [
      {
        id: 235,
        station_id: 204,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:48:58.000000Z",
        updated_at: "2024-03-16T07:48:58.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 205,
    uuid: "14d7355f-1cab-4984-9b49-e3cd7e2e0eb9",
    name: "Himyagya business Pvt Ltd",
    city: "Ghorahi",
    province: "Lumbini",
    address: "tulsipur highway, Ghorahi 14",
    telephone: "9857830336",
    type: ["car"],
    latitude: "28.15447925682237",
    longitude: "82.32349052791959",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T07:50:32.000000Z",
    updated_at: "2024-03-16T07:50:32.000000Z",
    image: null,
    plugs: [
      {
        id: 236,
        station_id: 205,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:50:32.000000Z",
        updated_at: "2024-03-16T07:50:32.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 206,
    uuid: "28c7d9b0-7d36-483f-abb6-31270a0ef516",
    name: "Gurkha Inn Village Hotel Pvt. Ltd",
    city: "Gorkha",
    province: "Gandaki",
    address: "Abukhaireni-Gorkha Hwy, Gorkha Bazar 34000",
    telephone: "064420206",
    type: ["car"],
    latitude: "27.997274329169052",
    longitude: "84.62440151075059",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:52:33.000000Z",
    updated_at: "2024-03-16T07:52:33.000000Z",
    image: null,
    plugs: [
      {
        id: 237,
        station_id: 206,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:52:33.000000Z",
        updated_at: "2024-03-16T07:52:33.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 207,
    uuid: "bcf5bc55-7047-45f7-b808-fb7ef49c14a0",
    name: "Hotel Lido Inn",
    city: "Hetauda",
    province: "Bagmati",
    address: "MainRoad 10, Hetauda 44107",
    telephone: "9865212601",
    type: ["car"],
    latitude: "27.428540622000135",
    longitude: "85.03048859497542",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:53:37.000000Z",
    updated_at: "2024-03-16T07:53:37.000000Z",
    image: null,
    plugs: [
      {
        id: 238,
        station_id: 207,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:53:37.000000Z",
        updated_at: "2024-03-16T07:53:37.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 208,
    uuid: "233f70b7-06ec-487a-8f08-2af0deb49a1e",
    name: "Hotel Green View",
    city: "Illam",
    province: "Koshi",
    address: "Ilam-7 , Nabin Chowk/Campus Road",
    telephone: "027523616",
    type: ["car"],
    latitude: "26.91134231032027",
    longitude: "87.92374896741946",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T07:54:49.000000Z",
    updated_at: "2024-03-16T07:54:49.000000Z",
    image: null,
    plugs: [
      {
        id: 239,
        station_id: 208,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T07:54:49.000000Z",
        updated_at: "2024-03-16T07:54:49.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 209,
    uuid: "4418ed9e-8533-405b-bfe0-7b0dfa39937e",
    name: "Baba Ko Dhaba - Hotel in Janakpur",
    city: "Janakpur",
    province: "Madhesh",
    address: "Laxminiya Janakpur Dham, 45600",
    telephone: "9854098540",
    type: ["car"],
    latitude: "26.777202835092076",
    longitude: "85.93392862865802",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:03:06.000000Z",
    updated_at: "2024-03-16T12:03:06.000000Z",
    image: null,
    plugs: [
      {
        id: 240,
        station_id: 209,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:03:06.000000Z",
        updated_at: "2024-03-16T12:03:06.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 211,
    uuid: "b58844c2-18e7-4025-8c5b-b7945dff659e",
    name: "Look Hotel and Lodge",
    city: "Chisapani",
    province: "Sudurpaschim",
    address: "J7VJ+3G2 karnali chisapani, Lamki Chuha 21800",
    telephone: "091414015",
    type: ["car"],
    latitude: "28.642809001432575",
    longitude: "81.2813406012617",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:07:29.000000Z",
    updated_at: "2024-03-16T12:07:29.000000Z",
    image: null,
    plugs: [
      {
        id: 242,
        station_id: 211,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:07:29.000000Z",
        updated_at: "2024-03-16T12:07:29.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 212,
    uuid: "b817d30c-592b-4a95-922f-a9d2aa458a67",
    name: "Sipradi Trading Pvt. Ltd",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Ganeshman Singh Rd, Kathmandu 44614",
    telephone: "014672587",
    type: ["car"],
    latitude: "27.696505325208513",
    longitude: "85.29408201712171",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:09:27.000000Z",
    updated_at: "2024-03-16T12:09:27.000000Z",
    image: null,
    plugs: [
      {
        id: 243,
        station_id: 212,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:09:27.000000Z",
        updated_at: "2024-03-16T12:09:27.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 213,
    uuid: "b5e25d18-6e39-48a7-8c60-6d210324ce31",
    name: "TATA Motors Kathmandu-Western Motors",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Narayan Gopal Marg, Kathmandu 00977",
    telephone: "014416716",
    type: ["car"],
    latitude: "27.731514625526067",
    longitude: "85.3283132983155",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:11:50.000000Z",
    updated_at: "2024-03-16T12:11:50.000000Z",
    image: null,
    plugs: [
      {
        id: 244,
        station_id: 213,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:11:50.000000Z",
        updated_at: "2024-03-16T12:11:50.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 214,
    uuid: "8167b223-12a1-459c-8bcf-634923703337",
    name: "Sipradi Trading Pvt Ltd, Tripureswor Office",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Tripura Marg, Kathmandu 44026",
    telephone: "014230024",
    type: ["car"],
    latitude: "27.69210932743742",
    longitude: "85.31617827039376",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:13:16.000000Z",
    updated_at: "2024-03-16T12:13:16.000000Z",
    image: null,
    plugs: [
      {
        id: 245,
        station_id: 214,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:13:16.000000Z",
        updated_at: "2024-03-16T12:13:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 215,
    uuid: "b16f28cc-9a0c-4e13-b10a-0b56b671d591",
    name: "Sipradi Trading P. Ltd, Naikap",
    city: "Kathmandu",
    province: "Bagmati",
    address: "M7Q5+G8H, Kalankisthan Rd, Chandragiri 44600",
    telephone: "014311501",
    type: ["car"],
    latitude: "27.686880073456685",
    longitude: "85.25888818778549",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:14:16.000000Z",
    updated_at: "2024-03-16T12:14:16.000000Z",
    image: null,
    plugs: [
      {
        id: 246,
        station_id: 215,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:14:16.000000Z",
        updated_at: "2024-03-16T12:14:16.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 216,
    uuid: "c0d262b0-0ca9-4e65-abd1-8b26e0e05cb6",
    name: "Sipradi Kapan (Tata Motors Service Center)",
    city: "Kathmandu",
    province: "Bagmati",
    address: "P8RX+HXP, Kathmandu 44600",
    telephone: "015903106",
    type: ["car"],
    latitude: "27.741623814449415",
    longitude: "85.35119838819192",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:15:26.000000Z",
    updated_at: "2024-03-21T15:18:49.000000Z",
    image: null,
    plugs: [
      {
        id: 247,
        station_id: 216,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:15:26.000000Z",
        updated_at: "2024-03-16T12:15:26.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 286,
        station_id: 216,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T15:18:49.000000Z",
        updated_at: "2024-03-21T15:18:49.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 217,
    uuid: "3f44960c-dde0-4761-9354-dbb62b37f6b5",
    name: "Chhaya Center, Thamel",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Chhaya Devi Complex, Amrit Marg, Kathmandu 44600",
    telephone: "015252125",
    type: ["car"],
    latitude: "27.715635126275",
    longitude: "85.31336572150595",
    amenities: ["wifi", "parking", "food", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:16:40.000000Z",
    updated_at: "2024-03-16T12:16:40.000000Z",
    image: null,
    plugs: [
      {
        id: 248,
        station_id: 217,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:16:40.000000Z",
        updated_at: "2024-03-16T12:16:40.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 218,
    uuid: "041fe090-97ec-4d90-ac69-36eabd459b63",
    name: "Sitara Resort Pvt Ltd",
    city: "Kawasoti",
    province: "Bagmati",
    address: "J4M5+MWC, Kawasoti 33000",
    telephone: "078541030",
    type: ["car"],
    latitude: "27.634360704638812",
    longitude: "84.10983933417363",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:18:38.000000Z",
    updated_at: "2024-03-16T12:18:38.000000Z",
    image: null,
    plugs: [
      {
        id: 249,
        station_id: 218,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:18:38.000000Z",
        updated_at: "2024-03-16T12:18:38.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 219,
    uuid: "a6ea1ac3-83fc-42ac-9609-e4a9a74e4af6",
    name: "Hotel Central Plaza",
    city: "Kohalpur",
    province: "Lumbini",
    address: "Dhangadi Way, Kiran Khola, Kohalpur 21904",
    telephone: "081410140",
    type: ["car"],
    latitude: "28.198101999147426",
    longitude: "81.6612684699257",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:20:57.000000Z",
    updated_at: "2024-03-16T12:20:57.000000Z",
    image: null,
    plugs: [
      {
        id: 250,
        station_id: 219,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:20:57.000000Z",
        updated_at: "2024-03-16T12:20:57.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 220,
    uuid: "5bf56898-1c8d-4a55-aee8-d426f9759d88",
    name: "Girija Automobile Pvt. Ltd",
    city: "Lahan",
    province: "Madhesh",
    address: "Lahan",
    telephone: "9818085908",
    type: ["car"],
    latitude: "26.714106181646528",
    longitude: "86.47221350548345",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:25:19.000000Z",
    updated_at: "2024-03-16T12:25:19.000000Z",
    image: null,
    plugs: [
      {
        id: 251,
        station_id: 220,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:25:19.000000Z",
        updated_at: "2024-03-16T12:25:19.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 221,
    uuid: "0f6624f2-0136-4cf2-83a2-0a41b4694a5d",
    name: "My Hotel, Pokhara",
    city: "Pokhara",
    province: "Gandaki",
    address: "Pokhara 33700",
    telephone: "061590751",
    type: ["car"],
    latitude: "28.207410970627688",
    longitude: "83.95941476879807",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:26:27.000000Z",
    updated_at: "2024-03-16T12:26:27.000000Z",
    image: null,
    plugs: [
      {
        id: 252,
        station_id: 221,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:26:27.000000Z",
        updated_at: "2024-03-16T12:26:27.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 222,
    uuid: "659455c1-e0fe-491d-9ddc-5c095fbc7b87",
    name: "Tata Motors Customer Care - Sipradi Service",
    city: "Lalitpur",
    province: "Bagmati",
    address: "Baghdole, Ring Rd Dakshin, Lalitpur 44600",
    telephone: "015188114",
    type: ["car"],
    latitude: "27.6739819654857",
    longitude: "85.30163710904942",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:27:47.000000Z",
    updated_at: "2024-03-16T12:28:17.000000Z",
    image: null,
    plugs: [
      {
        id: 253,
        station_id: 222,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:27:47.000000Z",
        updated_at: "2024-03-16T12:27:47.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 223,
    uuid: "397ac169-7d17-4857-87da-d70a0fdec5ea",
    name: "TATA Motors Lalitpur - JB Automotive Company",
    city: "Lalitpur",
    province: "Bagmati",
    address: "M85F+26, Lalitpur 44600",
    telephone: "9801096969",
    type: ["car"],
    latitude: "27.66950456735067",
    longitude: "85.33695419979155",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:36:55.000000Z",
    updated_at: "2024-03-16T12:36:55.000000Z",
    image: null,
    plugs: [
      {
        id: 254,
        station_id: 223,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:36:55.000000Z",
        updated_at: "2024-03-16T12:36:55.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 224,
    uuid: "97a32029-2713-473f-b518-c53e01307ff5",
    name: "Buddha Maya Gardens Hotel",
    city: "Lumbini",
    province: "Lumbini",
    address: "Lumbini sanskritik, Lumbini 32900",
    telephone: "9801033114",
    type: ["car"],
    latitude: "27.461952442540515",
    longitude: "83.28251900906241",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:43:42.000000Z",
    updated_at: "2024-03-16T12:43:42.000000Z",
    image: null,
    plugs: [
      {
        id: 255,
        station_id: 224,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:43:42.000000Z",
        updated_at: "2024-03-16T12:43:42.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 225,
    uuid: "6e609e97-c576-405b-938b-ddacd97bd89c",
    name: "Sipradi Trading - Mirchaiya Branch",
    city: "Mirchiya",
    province: "Madhesh",
    address: "Mirchaiya 56500",
    telephone: "9858045245",
    type: ["car"],
    latitude: "26.823530812665965",
    longitude: "86.2670195991634",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T12:55:51.000000Z",
    updated_at: "2024-03-16T12:55:51.000000Z",
    image: null,
    plugs: [
      {
        id: 256,
        station_id: 225,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:55:51.000000Z",
        updated_at: "2024-03-16T12:55:51.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 226,
    uuid: "0f20469f-3f23-441f-b846-267aef7bf345",
    name: "Chitwan Forest Resort",
    city: "Chitwan",
    province: "Bagmati",
    address: "Sauraha, Bachhauli - 2, Chitwan National Park, Nepal 32909",
    telephone: "056580052",
    type: ["car"],
    latitude: "27.58172197316344",
    longitude: "84.49369813650124",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:57:23.000000Z",
    updated_at: "2024-03-16T12:57:23.000000Z",
    image: null,
    plugs: [
      {
        id: 257,
        station_id: 226,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:57:23.000000Z",
        updated_at: "2024-03-16T12:57:23.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 227,
    uuid: "a1f6eeff-4f20-4516-8544-58ff9c10ca73",
    name: "Hotel Earth Light",
    city: "Ratnanagar",
    province: "Bagmati",
    address: "HFQR+G78, Ratnanagar 44200",
    telephone: "056580454",
    type: ["car"],
    latitude: "27.58886490994944",
    longitude: "84.4907058046082",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T12:59:24.000000Z",
    updated_at: "2024-03-16T12:59:24.000000Z",
    image: null,
    plugs: [
      {
        id: 258,
        station_id: 227,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T12:59:24.000000Z",
        updated_at: "2024-03-16T12:59:24.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 228,
    uuid: "6209d363-6243-4b9c-b474-eaab2228d2d0",
    name: "Jungle Safari Lodge (TATA Fast AC)",
    city: "Ratnanagar",
    province: "Bagmati",
    address: "HFHV+9Q3, 113, Ratnanagar 44200",
    telephone: "056580046",
    type: ["car"],
    latitude: "27.578455621708535",
    longitude: "84.49419625368678",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:01:40.000000Z",
    updated_at: "2024-03-18T06:46:16.000000Z",
    image: null,
    plugs: [
      {
        id: 259,
        station_id: 228,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:01:40.000000Z",
        updated_at: "2024-03-16T13:01:40.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 229,
    uuid: "07c5e01f-517a-45ca-b7be-efac1ba12373",
    name: "Central Palms Hotel (TATA Fast AC)",
    city: "Bharatpur",
    province: "Bagmati",
    address: "MCV8+7JJ Central Palms Hotel, Bharatpur 44207",
    telephone: "056572071",
    type: ["car"],
    latitude: "27.692172256413762",
    longitude: "84.4142276106285",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:06:11.000000Z",
    updated_at: "2024-03-18T06:46:12.000000Z",
    image: null,
    plugs: [
      {
        id: 260,
        station_id: 229,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:06:11.000000Z",
        updated_at: "2024-03-16T13:06:11.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 230,
    uuid: "1d509a4a-eb48-4899-8935-eafab19b59a6",
    name: "Sipradi Trading Pvt. Ltd., Dharke Branch",
    city: "Dharke",
    province: "Bagmati",
    address: "Thakre 45100",
    telephone: "16600155777",
    type: ["car"],
    latitude: "27.744440880931375",
    longitude: "85.10739146340629",
    amenities: ["parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T13:09:19.000000Z",
    updated_at: "2024-03-21T14:49:56.000000Z",
    image: null,
    plugs: [
      {
        id: 261,
        station_id: 230,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:09:19.000000Z",
        updated_at: "2024-03-16T13:09:19.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
      {
        id: 281,
        station_id: 230,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T14:49:56.000000Z",
        updated_at: "2024-03-21T14:49:56.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 231,
    uuid: "e2e4c4cd-cdcc-42d7-8c6f-57d8ac9329f9",
    name: "Tata service center",
    city: "Nepalgunj",
    province: "Lumbini",
    address: "Ratna Rajmarg, H12, Nepalgunj 21900",
    telephone: "081403083",
    type: ["car"],
    latitude: "28.075139207451343",
    longitude: "81.63543920615363",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T13:43:34.000000Z",
    updated_at: "2024-03-21T14:57:11.000000Z",
    image: null,
    plugs: [
      {
        id: 262,
        station_id: 231,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:43:34.000000Z",
        updated_at: "2024-03-16T13:43:34.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 232,
    uuid: "e6d10c5c-7d5e-41eb-864c-f0bc343c6b70",
    name: "Rudrapriya Pvt Ltd",
    city: "Nepalgunj",
    province: "Lumbini",
    address: "3JCM+V26, Nepalgunj 21900",
    telephone: "081551415",
    type: ["car"],
    latitude: "28.07216954441915",
    longitude: "81.63256154999554",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T13:45:41.000000Z",
    updated_at: "2024-03-21T14:57:06.000000Z",
    image: null,
    plugs: [
      {
        id: 263,
        station_id: 232,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:45:41.000000Z",
        updated_at: "2024-03-16T13:45:41.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 233,
    uuid: "d7499c64-473e-4fb3-be7f-7bf61365239c",
    name: "Hotel Siddhartha Park Regency (TATA Fast AC)",
    city: "Parasi",
    province: "Lumbini",
    address: "Sunwal-Parasi Road, Ramgram 33007",
    telephone: "078590160",
    type: ["car"],
    latitude: "27.537487134288643",
    longitude: "83.66695321727276",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:49:38.000000Z",
    updated_at: "2024-03-18T06:45:48.000000Z",
    image: null,
    plugs: [
      {
        id: 264,
        station_id: 233,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:49:38.000000Z",
        updated_at: "2024-03-16T13:49:38.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 234,
    uuid: "e70d79b9-f8f8-4199-b426-04f69f37c058",
    name: "Atithi Resort & SPA Pvt. Ltd (TATA Fast AC)",
    city: "Pokhara",
    province: "Gandaki",
    address: "Durbar Marg, Pokhara 33700",
    telephone: "061456760",
    type: ["car"],
    latitude: "28.20682372961044",
    longitude: "83.96691618914569",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:51:47.000000Z",
    updated_at: "2024-03-18T06:45:44.000000Z",
    image: null,
    plugs: [
      {
        id: 265,
        station_id: 234,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:51:47.000000Z",
        updated_at: "2024-03-16T13:51:47.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 235,
    uuid: "22484432-dc47-4a62-a391-aed7f780b260",
    name: "Hotel Crown Himalayas (TATA Fast AC)",
    city: "Pokhara",
    province: "Gandaki",
    address: "Baidam-6,gaurighat, Lakeside, Pokhara 00977",
    telephone: "061452300",
    type: ["car"],
    latitude: "28.207032154612882",
    longitude: "83.96351474183064",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:54:44.000000Z",
    updated_at: "2024-03-18T06:45:40.000000Z",
    image: null,
    plugs: [
      {
        id: 266,
        station_id: 235,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:54:44.000000Z",
        updated_at: "2024-03-16T13:54:44.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 236,
    uuid: "0d6ffc92-f27b-4367-8628-cb74351b70c3",
    name: "Hotel Four Seasons (TATA Fast AC)",
    city: "Pokhara",
    province: "Gandaki",
    address: "6X55+8QX, Lakeside Rd, Pokhara 33700",
    telephone: "061455777",
    type: ["car"],
    latitude: "28.20839141358115",
    longitude: "83.95950811961505",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:55:52.000000Z",
    updated_at: "2024-03-18T06:45:35.000000Z",
    image: null,
    plugs: [
      {
        id: 267,
        station_id: 236,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:55:52.000000Z",
        updated_at: "2024-03-16T13:55:52.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 237,
    uuid: "f228a639-1139-4ed4-aa17-744fc6ec07c1",
    name: "Deep Motors pvt.ltd (TATA Fast AC)",
    city: "Pokhara",
    province: "Gandaki",
    address: "6X4V+JGV Buddhachowk, Birauta, Pokhara 33700",
    telephone: "061582475",
    type: ["car"],
    latitude: "28.206639953518103",
    longitude: "83.99389958550591",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-16T13:57:53.000000Z",
    updated_at: "2024-03-18T06:45:19.000000Z",
    image: null,
    plugs: [
      {
        id: 268,
        station_id: 237,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:57:53.000000Z",
        updated_at: "2024-03-16T13:57:53.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 238,
    uuid: "78761e12-897f-4ce8-a03e-0da0f80b8c3a",
    name: "Majheri Resort and Spa (TATA Fast AC)",
    city: "Pokhara",
    province: "Gandaki",
    address: "Lalupate Margha, Pokhara 33700",
    telephone: "9856089226",
    type: ["car"],
    latitude: "28.212317571999307",
    longitude: "83.96040026428872",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-16T13:59:32.000000Z",
    updated_at: "2024-03-18T06:44:07.000000Z",
    image: null,
    plugs: [
      {
        id: 269,
        station_id: 238,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-16T13:59:32.000000Z",
        updated_at: "2024-03-16T13:59:32.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 239,
    uuid: "4e19b698-e361-41e4-bb87-4d16042c35d1",
    name: "Hotel Simkhola (TATA Fast AC)",
    city: "Kulekhani",
    province: "Bagmati",
    address: "H5J4+FC4, Kulekhani 44100",
    telephone: "9841019231",
    type: ["car"],
    latitude: "27.58126853156612",
    longitude: "85.15629743965472",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-17T07:20:40.000000Z",
    updated_at: "2024-03-18T06:44:03.000000Z",
    image: null,
    plugs: [
      {
        id: 270,
        station_id: 239,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:20:40.000000Z",
        updated_at: "2024-03-17T07:20:40.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 240,
    uuid: "6f057b4d-ac1b-4e0c-a9db-62f6b6a1213e",
    name: "Hotel Navaratna (TATA Fast AC)",
    city: "Sukkhad",
    province: "Sudurpaschim",
    address: "Darakh-5 Sukhad Kailali,, Mahendra Hwy, 10900",
    telephone: "091403005",
    type: ["car"],
    latitude: "28.676358807879268",
    longitude: "80.9581081662328",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-17T07:25:55.000000Z",
    updated_at: "2024-03-18T06:43:59.000000Z",
    image: null,
    plugs: [
      {
        id: 272,
        station_id: 240,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:25:55.000000Z",
        updated_at: "2024-03-17T07:25:55.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 241,
    uuid: "fdc04c1d-81c5-4865-869f-3e98e94871d6",
    name: "Sandy Beach Resort (TATA Fast AC)",
    city: "Sukute",
    province: "Bagmati",
    address: "MQX3+GFC, Bus Stand, Araniko Highway, Sangachok 45314",
    telephone: "9847369390",
    type: ["car"],
    latitude: "27.6990274726338",
    longitude: "85.75363228729495",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-17T07:27:00.000000Z",
    updated_at: "2024-03-18T06:43:54.000000Z",
    image: null,
    plugs: [
      {
        id: 273,
        station_id: 241,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:27:00.000000Z",
        updated_at: "2024-03-17T07:27:00.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 242,
    uuid: "f2fb4fe8-e060-44c6-8ae5-d1e4b3fc131f",
    name: "Sharma hotel and lodge (TATA Fast AC)",
    city: "Surkhet",
    province: "Karnali",
    address: "Babai, Surkhet",
    telephone: "9868983340",
    type: ["car"],
    latitude: "28.355963550741123",
    longitude: "81.71291219407729",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-17T07:28:31.000000Z",
    updated_at: "2024-03-18T06:43:50.000000Z",
    image: null,
    plugs: [
      {
        id: 274,
        station_id: 242,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:28:31.000000Z",
        updated_at: "2024-03-17T07:28:31.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 243,
    uuid: "e7470e78-3158-47cf-9fe5-b76ebf12c443",
    name: "Sipradi Trading Pvt Ltd, Birendranagar (TATA Fast AC)",
    city: "Birendranagar",
    province: "Karnali",
    address: "HJFV+69J, Birendranagar 21700",
    telephone: "083411063",
    type: ["car"],
    latitude: "28.57312835031974",
    longitude: "81.6434306719765",
    amenities: ["parking", "restroom"],
    vendor: "tata",
    created_at: "2024-03-17T07:29:56.000000Z",
    updated_at: "2024-03-18T06:43:45.000000Z",
    image: null,
    plugs: [
      {
        id: 275,
        station_id: 243,
        plug: "ccs2",
        power: "7.2kw",
        type: "AC",
        created_at: "2024-03-17T07:29:56.000000Z",
        updated_at: "2024-03-17T07:29:56.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 244,
    uuid: "a105908c-6acd-46c2-8534-66a23c679074",
    name: "S.S. View Restaurant, Syangja (TATA Fast AC)",
    city: "Syangja",
    province: "Gandaki",
    address: "4VJ7+27W, H10, Putalibazar 33800",
    telephone: "9856029990",
    type: ["car"],
    latitude: "28.13016626497154",
    longitude: "83.86327696105684",
    amenities: ["wifi", "parking", "food", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-17T07:31:30.000000Z",
    updated_at: "2024-03-18T06:43:38.000000Z",
    image: null,
    plugs: [
      {
        id: 276,
        station_id: 244,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:31:30.000000Z",
        updated_at: "2024-03-17T07:31:30.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 245,
    uuid: "4c9d0774-6ec3-4bec-943d-f988243825c8",
    name: "Adhikari Hotel and Lodge (TATA Fast AC)",
    city: "Beltar",
    province: "Koshi",
    address: "RV3H+RW7, Beltar Basaha 56300",
    telephone: "9803417005",
    type: ["car"],
    latitude: "26.80457423638446",
    longitude: "86.87987309286834",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-17T07:36:18.000000Z",
    updated_at: "2024-03-18T06:43:32.000000Z",
    image: null,
    plugs: [
      {
        id: 277,
        station_id: 245,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:36:18.000000Z",
        updated_at: "2024-03-17T07:36:18.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 246,
    uuid: "22a9ac27-d065-4989-aa52-19ce8607f0cd",
    name: "Hotel Town Hall (TATA Fast AC)",
    city: "Gaighat",
    province: "Koshi",
    address: "QMPW+RPC, Gaighat 56300",
    telephone: "035422665",
    type: ["car"],
    latitude: "26.78742311846594",
    longitude: "86.69711723325807",
    amenities: [
      "wifi",
      "parking",
      "food",
      "coffee",
      "accomodation",
      "restroom",
    ],
    vendor: "tata",
    created_at: "2024-03-17T07:41:57.000000Z",
    updated_at: "2024-03-18T06:43:17.000000Z",
    image: null,
    plugs: [
      {
        id: 278,
        station_id: 246,
        plug: "ccs2",
        power: "7.2Kw",
        type: "AC",
        created_at: "2024-03-17T07:41:57.000000Z",
        updated_at: "2024-03-17T07:41:57.000000Z",
        count: 1,
        icon: "ccs2-ac",
      },
    ],
  },
  {
    id: 247,
    uuid: "7a63a470-1228-4340-8fca-13cb16ad7e6b",
    name: "CG Fast Charging Station",
    city: "Benighat",
    province: "Bagmati",
    address: "RQ7Q+5MH, H04, Benighat 45100, Nepal",
    telephone: "9851051074",
    type: ["car"],
    latitude: "27.813021617662002",
    longitude: "84.78929850426947",
    amenities: ["parking", "food", "coffee", "restroom"],
    vendor: "cg",
    created_at: "2024-03-17T16:39:39.000000Z",
    updated_at: "2024-03-17T16:41:12.000000Z",
    image: null,
    plugs: [
      {
        id: 279,
        station_id: 247,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-17T16:39:39.000000Z",
        updated_at: "2024-03-17T16:39:39.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 280,
        station_id: 247,
        plug: "gbt",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-17T16:41:12.000000Z",
        updated_at: "2024-03-17T16:41:12.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 248,
    uuid: "ab6b78eb-ec2a-43e7-bac7-24abe4cca38d",
    name: "The highway coffee shot",
    city: "Malekhu",
    province: "Bagmati",
    address: "Benighat 45100",
    telephone: null,
    type: ["car"],
    latitude: "27.812495335817196",
    longitude: "84.83188187364938",
    amenities: ["wifi", "parking", "food", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-21T14:53:14.000000Z",
    updated_at: "2024-03-21T14:53:51.000000Z",
    image: null,
    plugs: [
      {
        id: 282,
        station_id: 248,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T14:53:14.000000Z",
        updated_at: "2024-03-21T14:53:14.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 249,
    uuid: "57f07db1-6e07-40ed-a4a8-d278752bd216",
    name: "Sipradi Service Center, Baghdole",
    city: "Kathmandu",
    province: "Bagmati",
    address: "Ring Rd Dakshin, Lalitpur 44600",
    telephone: "015188114",
    type: ["car"],
    latitude: "27.674017961030096",
    longitude: "85.30173469972182",
    amenities: ["wifi", "parking", "coffee", "restroom"],
    vendor: "tata",
    created_at: "2024-03-21T15:06:30.000000Z",
    updated_at: "2024-03-21T15:06:30.000000Z",
    image: null,
    plugs: [
      {
        id: 285,
        station_id: 249,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-21T15:06:30.000000Z",
        updated_at: "2024-03-21T15:06:30.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 250,
    uuid: "00902847-40d3-4154-8bad-e4a9e87a3e20",
    name: "Dawanya Devi Oil & Charging Station",
    city: "Dumkibas",
    province: "Lumbini",
    address: "Dumkibas 33000",
    telephone: "078416135",
    type: ["car"],
    latitude: "27.5832414176448",
    longitude: "83.86751690385296",
    amenities: ["parking", "restroom"],
    vendor: null,
    created_at: "2024-03-23T16:36:48.000000Z",
    updated_at: "2024-03-23T16:36:48.000000Z",
    image: null,
    plugs: [
      {
        id: 288,
        station_id: 250,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-23T16:36:48.000000Z",
        updated_at: "2024-03-23T16:36:48.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
  {
    id: 251,
    uuid: "05f13bf4-a744-4689-9990-af32001fb513",
    name: "Sangam Shree Oil, Sindhuli",
    city: "Sindhuli",
    province: "Bagmati",
    address: "CV7C+522, Jhangajholi Ratamata 45900",
    telephone: "9811724858",
    type: ["car"],
    latitude: "27.412896592321566",
    longitude: "85.87000753038377",
    amenities: ["parking", "restroom"],
    vendor: "cg",
    created_at: "2024-03-23T16:47:12.000000Z",
    updated_at: "2024-03-23T16:47:12.000000Z",
    image: null,
    plugs: [
      {
        id: 289,
        station_id: 251,
        plug: "gbt",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-23T16:47:12.000000Z",
        updated_at: "2024-03-23T16:47:12.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 252,
    uuid: "26ae3e73-1e2b-4fa8-86c4-a6b72d6049a3",
    name: "CG Charging Station, Hetauda",
    city: "Hetauda",
    province: "Bagmati",
    address: "C27G+269, Mahendra Hwy, Hetauda 44107, Nepal",
    telephone: null,
    type: ["car"],
    latitude: "27.412553290022945",
    longitude: "85.02562582571449",
    amenities: ["parking", "food", "coffee", "restroom"],
    vendor: "cg",
    created_at: "2024-03-24T05:20:43.000000Z",
    updated_at: "2024-03-24T05:20:51.000000Z",
    image: null,
    plugs: [
      {
        id: 290,
        station_id: 252,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-24T05:20:43.000000Z",
        updated_at: "2024-03-24T05:20:43.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 291,
        station_id: 252,
        plug: "gbt",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-24T05:20:43.000000Z",
        updated_at: "2024-03-24T05:20:43.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 253,
    uuid: "08dd10f7-55c9-4ae3-bd36-61721945c278",
    name: "Charging Station, Kurintar",
    city: "Kurintar",
    province: "Bagmati",
    address: "VJ95+R34, Darechok 44200",
    telephone: null,
    type: ["car"],
    latitude: "27.86964069254462",
    longitude: "84.608583260696",
    amenities: ["parking"],
    vendor: null,
    created_at: "2024-03-30T05:30:53.000000Z",
    updated_at: "2024-03-30T05:30:53.000000Z",
    image: null,
    plugs: [
      {
        id: 292,
        station_id: 253,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-03-30T05:30:53.000000Z",
        updated_at: "2024-03-30T05:30:53.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
      {
        id: 293,
        station_id: 253,
        plug: "gbt",
        power: "60Kw",
        type: "DC",
        created_at: "2024-03-30T05:30:53.000000Z",
        updated_at: "2024-03-30T05:30:53.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 254,
    uuid: "003581a5-a279-4f67-90fa-f4bdbbd0af13",
    name: "Minolta Investment, Birgunj",
    city: "Birgunj",
    province: "Madhesh",
    address: "Birgunj - Ghahawa 08, Birguj, Buspark, Birgunj 44300, Nepal",
    telephone: "9820997200",
    type: ["car"],
    latitude: "27.01133225465793",
    longitude: "84.88658273014585",
    amenities: ["parking", "restroom"],
    vendor: "cg",
    created_at: "2024-03-30T05:41:49.000000Z",
    updated_at: "2024-03-30T05:41:49.000000Z",
    image: null,
    plugs: [
      {
        id: 294,
        station_id: 254,
        plug: "gbt",
        power: "30Kw",
        type: "DC",
        created_at: "2024-03-30T05:41:49.000000Z",
        updated_at: "2024-03-30T05:41:49.000000Z",
        count: 1,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 255,
    uuid: "5fd7fd8c-de77-498a-ba3f-70f89b781810",
    name: "Chiyabari Charging Station",
    city: "Sindhuli",
    province: "Bagmati",
    address: "Chiyabari-02, Bhadrakali 45900",
    telephone: "9801602704",
    type: ["car"],
    latitude: "27.247571707447356",
    longitude: "85.9350569713175",
    amenities: ["parking", "food", "restroom"],
    vendor: null,
    created_at: "2024-04-10T06:41:50.000000Z",
    updated_at: "2024-04-10T06:41:50.000000Z",
    image: null,
    plugs: [
      {
        id: 295,
        station_id: 255,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-04-10T06:41:50.000000Z",
        updated_at: "2024-04-10T06:41:50.000000Z",
        count: 2,
        icon: "ccs2-dc",
      },
      {
        id: 296,
        station_id: 255,
        plug: "gbt",
        power: "60Kw",
        type: "DC",
        created_at: "2024-04-10T06:41:50.000000Z",
        updated_at: "2024-04-10T06:41:50.000000Z",
        count: 2,
        icon: "gbt-dc",
      },
      {
        id: 297,
        station_id: 255,
        plug: "gbt",
        power: "30Kw",
        type: "DC",
        created_at: "2024-04-10T06:41:50.000000Z",
        updated_at: "2024-04-10T06:41:50.000000Z",
        count: 2,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 256,
    uuid: "aa02f54a-48bd-4362-9b64-ce5bf903f089",
    name: "Charikot Charging Station",
    city: "Charikot",
    province: "Bagmati",
    address: "M28W+J7X, Bhimeshwor Municipality 45500",
    telephone: "+9779851043843",
    type: ["car"],
    latitude: "27.666830976822567",
    longitude: "86.04565770897115",
    amenities: ["parking", "food", "coffee", "restroom"],
    vendor: null,
    created_at: "2024-04-14T11:06:40.000000Z",
    updated_at: "2024-04-14T11:06:40.000000Z",
    image: null,
    plugs: [
      {
        id: 298,
        station_id: 256,
        plug: "ccs2",
        power: "60Kw",
        type: "DC",
        created_at: "2024-04-14T11:06:40.000000Z",
        updated_at: "2024-04-14T11:06:40.000000Z",
        count: 2,
        icon: "ccs2-dc",
      },
      {
        id: 299,
        station_id: 256,
        plug: "gbt",
        power: "60Kw",
        type: "DC",
        created_at: "2024-04-14T11:06:40.000000Z",
        updated_at: "2024-04-14T11:06:40.000000Z",
        count: 3,
        icon: "gbt-dc",
      },
    ],
  },
  {
    id: 257,
    uuid: "3c1f9375-e506-4ab3-a018-af838f6c2f57",
    name: "Everest Petrol Pump",
    city: "Bhairahawa",
    province: "Lumbini",
    address: "GFF4+W3Q, Siddharthanagar 32900",
    telephone: "071572859",
    type: ["car"],
    latitude: "27.52463925453166",
    longitude: "83.45510359640248",
    amenities: ["wifi", "parking", "restroom"],
    vendor: "byd",
    created_at: "2024-04-27T11:12:50.000000Z",
    updated_at: "2024-04-27T11:12:50.000000Z",
    image: null,
    plugs: [
      {
        id: 300,
        station_id: 257,
        plug: "ccs2",
        power: "30Kw",
        type: "DC",
        created_at: "2024-04-27T11:12:50.000000Z",
        updated_at: "2024-04-27T11:12:50.000000Z",
        count: 1,
        icon: "ccs2-dc",
      },
    ],
  },
];

const HomePage: React.FC = () => {
  const [currentLocation, setCurrentLocation] = useState<TLocationData>();
  const [stationFilteredItems, setStationFilteredItems] =
    useState<TCharggingStation[]>(listOfStationsData);
  useEffect(() => {
    getLocation();
    const uniqueNames = Array.from(
      new Set(listOfStationsData.map((item: any) => item.vendor))
    );
    console.log(uniqueNames);
  }, []);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            navigator.permissions.query({ name: "geolocation" });
          } else {
            navigator.geolocation.getCurrentPosition(success, error);
          }
        });
    } else {
      alert("Geolocation is not supported in your browser.");
    }
  }
  const success = (data: GeolocationPosition) => {
    setCurrentLocation(data.coords);
  };
  const error = (error: GeolocationPositionError) => {
    console.log(error);
  };

  const onGetDirection = (value: TCharggingStation) => {
    console.log(value);
  };
  const searchStations = (searchText: string) => {
    const filteredItems = listOfStationsData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.address.toLowerCase().includes(searchText.toLowerCase()) ||
        item.city.toLowerCase().includes(searchText.toLowerCase())
    );
    setStationFilteredItems(filteredItems);
  };

  useEffect(() => {
    EventEmitter.subscribe("Location", (data: any) => {
      searchStations(data.name);
    });
  }, []);

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full h-[70vh] lg:w-3/4 lg:h-[90vh]">
        <OpenStreetMap stations={stationFilteredItems} />
      </div>
      <div className="w-full lg:w-1/4">
        <div className="w-full mx-auto pl-4 p-4 pb-0">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium sr-only"
          >
            Find
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search charging stations ..."
              required
              onChange={(value) => searchStations(value.target.value)}
            />
          </div>
        </div>
        <div className="max-h-[80vh] lg:w-70vh flex flex-row lg:flex-col gap-2 m-4 overflow-y-scroll">
          {stationFilteredItems.map(
            (item: TCharggingStation, index: number) => (
              <Card
                displayDirection={true}
                displayClose={false}
                key={index}
                content={item}
                onGetDirection={(data) => onGetDirection(data)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
