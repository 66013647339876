import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import React from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { TCharggingStation } from "Types";
import Card from "Components/Card/Card.Component";

type TLatLng = { latitude: number; longitude: number };
type TStationsList = {
  stations: TCharggingStation[];
};
const OpenStreetMap: React.FC<TStationsList> = ({ stations }) => {
  const customIcon = new L.Icon({
    //creating a custom icon to use in Marker
    iconUrl: "/icons/Charging_Icon.png",
    iconSize: [50, 50],
    iconAnchor: [5, 30],
  });

  function MapView() {
    let map = useMap();
    // map.addLayer({
      
    // })
    // map.setView([latitude, longitude], map.getZoom());
    //Sets geographical center and zoom for the view of the map
    return null;
  }
  return (
    <MapContainer
      center={[27.700769, 85.30014]} // Kathmandu location
      zoom={7}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>
    contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {stations.map((item: TCharggingStation) => {
        return (
          <Marker
            icon={customIcon}
            position={[parseFloat(item.latitude), parseFloat(item.longitude)]}
          >
            <Popup>
              <Card
                displayClose={true}
                content={item}
                displayDirection={false}
                onGetDirection={() => {}}
              />
            </Popup>
          </Marker>
        );
      })}
      <MapView />
    </MapContainer>
  );
};

export default OpenStreetMap;
