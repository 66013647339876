import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import HomePage from "./Pages/Home";
import DefaultLayout from "./Components/Header.Component";
import NotFoundPage from "./Pages/Not-Found";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<HomePage />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Route>
  )
);
export default router;
